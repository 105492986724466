import React, { useEffect, useState } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"
import Search from "../../../components/Search"
import Button from "../../../components/Button"
import { Col, Row } from "antd"
import {
  getAdmindashboardList,
  getAdmindashboardUpdateUser,
  userActiveRequest,
  userActiveSuccess
} from "./redux/action"
import { connect } from "react-redux"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import { history } from "../../../reduxStore/store"
import { Spinner } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { toast, Toaster } from "react-hot-toast"

const AdminDashboard = ({
  getAdmindashboardList,
  userListRequestList,
  getAdmindashboardUpdateUser,

  userListRequesting,
  userActiveRequest,
  activeRequest,
  userActiveSuccess,
  activeRequesting
}) => {
  const [loading, setLoading] = useState(0)

  const AdmindashboardId = localStorage.getItem("AdmindashboardId")
  useEffect(() => {
    getAdmindashboardList("")
  }, [])
  useEffect(() => {
    if (activeRequest.status) {
      getAdmindashboardList("")
      userActiveSuccess(false)
    }
  }, [activeRequest.status])
  const userActiveUnactive = (id, status) => {
    if (status == "Active") {
      const data = {
        status: false
      }
      userActiveRequest(data, id, showSuccessToast(status))
    } else {
      const data = {
        status: true
      }
      userActiveRequest(data, id, showSuccessToast(status))
    }
  }

  const showSuccessToast = status => {
    toast.success(
      `Status updated to ${(status === "Active") ? "inactive" : "active"}`,

    )
  }
  let timeoutId

  const HandelSearch = e => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      getAdmindashboardList(`?name=${e.target.value}`)
    }, 1000)
  }

  return (
    <HomeLayout dashboardHeader={true}>
      <section className="content-section adminDashboard-section">
        {/* <div className="headingStyle w-100">
          <div className="mb-3">
            <h1>Admin dashboard</h1>
          </div>
          <div className="mb-3">
            <form>
              <Search placeholder="Search by contractor" onChange={e => { }} />
            </form>
          </div>
          <div className="buttondiv mb-3">
            <Button
              title="Add New Fill Site"
              className="newFileStyle"
              onClick={e => history.push("new_file_site")}
            />
          </div>
        </div> */}
        <Toaster position="top-center" />
        <div className="headingStyle">
          <span
            style={{
              display: "flex",
              gap: "10px",
              flexGrow: "1",
              alignItems: "baseline"
            }}
          >
            <h1 className="w-100">Fill Sites List</h1>
          </span>
          <form>
            <Search
              placeholder="Search by fill site"
              onChange={e => {
                HandelSearch(e)
              }}
            />
          </form>
          <div className="buttondiv">
            <Button
              title="Add New Fill Site"
              className="newFileStyle"
              onClick={e => history.push("new-file-site")}
            />
          </div>
        </div>
        <div className="bodyScroll">
          {!userListRequestList.length && userListRequesting ? (
            [1, 2, 3].map(item => (
              <div className="">
                <Row className="contentStyle">
                  <Col span={17} className="border-bottom pb-4">
                    <Skeleton width="50%" />
                  </Col>
                  <Col
                    span={7}
                    className="text-end border-bottom pb-4"
                    style={{ display: "flex", gap: "5px" }}
                  >
                    <div style={{ flexBasis: "33%" }}>
                      <Skeleton width="100%" />
                    </div>
                    <div style={{ flexBasis: "33%" }}>
                      <Skeleton width="100%" />
                    </div>
                    <div style={{ flexBasis: "33%" }}>
                      <Skeleton width="100%" />
                    </div>
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="50%" />
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="50%" />
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="60%" />
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="60%" />
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="70%" />
                  </Col>
                  <Col span={12} className="border-bottom py-2">
                    <Skeleton width="70%" />
                  </Col>
                </Row>
              </div>
            ))
          ) : userListRequestList && userListRequestList?.length > 0 ? (
            userListRequestList.map((item, index) => {
              return (
                <Row className="contentStyle">
                  <span className="border-bottom w-100 d-md-flex">
                    <Col md={12} sm={24} key={index} className=' pb-4'>
                      <h1 style={{cursor:'pointer'}} className="heading-name" onClick={() => {
                          const AdmindashboardId = item?.id
                          localStorage.setItem("AdmindashboardId", AdmindashboardId)
                          getAdmindashboardList(`${AdmindashboardId}/`)
                          history.push("update-fill-site")
                        }}>
                        {item?.name ? item?.name : "---"}
                      </h1>
                    </Col>
                    <Col md={12} sm={24} className="text-end pb-4">
                      <Button
                        title="See Details"
                        className="BtnsStyle"
                        onClick={() => {
                          const AdmindashboardId = item?.id
                          localStorage.setItem("AdmindashboardId", AdmindashboardId)
                          getAdmindashboardList(`${AdmindashboardId}/`)
                          history.push("update-fill-site")
                        }}
                      />
                      <Button
                        title="Edit"
                        className="BtnsStyle"
                        onClick={e => {
                          const AdmindashboardId = item?.id
                          localStorage.setItem("AdmindashboardId", AdmindashboardId)
                          getAdmindashboardList(`${AdmindashboardId}/`)

                          history.push("update-fill-site")
                        }}
                      />
                      <Button
                        showSpinner={loading == index && activeRequesting}
                        key={index}
                        title={
                          item.status.charAt(0).toUpperCase() + item.status.slice(1)
                        }
                        className="BtnsStyle"
                        onClick={() => {
                          userActiveUnactive(item.id, item.status)

                          setLoading(index)
                        }}
                      />
                    </Col>
                  </span>
                  <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      Location:{" "}
                      <span>{item?.location ? item?.location : "---"}</span>
                    </h2>
                  </Col>
                  <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      Number of loads finished:{" "}
                      <span>
                        {item?.no_of_loads_finished
                          ? item?.no_of_loads_finished
                          : "---"}
                      </span>
                    </h2>
                  </Col>
                  <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      Contact Phone:{" "}
                      <span>
                        {item?.phone && !isNaN(Number(item?.phone.slice(1)))
                          ? item?.phone
                          : "---"}
                      </span>
                    </h2>
                  </Col>
                  <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      Number of loads scheduled:{" "}
                      <span>
                        {item?.no_of_loads_scheduled
                          ? item?.no_of_loads_scheduled
                          : "---"}
                      </span>
                    </h2>
                  </Col>
                  <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      Contact email:{" "}
                      <span>{item?.email ? item?.email : "---"}</span>
                    </h2>
                  </Col>
                  <Col sm={12} xs={24} className="border-bottom py-2">
                    <h2>
                      Freeload slots:{" "}
                      <span>
                        {item?.freeload_slot ? item?.freeload_slot : "---"}
                      </span>
                    </h2>
                  </Col>
                </Row>
              )
            })
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh"
              }}
            >
              <p>No Data Found</p>
            </div>
          )}
        </div>
      </section>
    </HomeLayout >
  )
}
// state.adminDashboardUserData.data?.length ? state.userListRequestList.data : [],

const mapStateToProps = state => ({
  userListRequestList: state.adminDashboardUserData.userListRequestList,
  updateUserRequest: state.adminDashboardUserData.updateUserRequest,

  userListRequesting: state.adminDashboardUserData.userListRequesting,
  activeRequest: state.adminDashboardUserData.activeRequest,
  activeRequesting: state.adminDashboardUserData.activeRequesting
})

const mapDispatchToProps = dispatch => ({
  getAdmindashboardList: data => dispatch(getAdmindashboardList(data)),
  getAdmindashboardUpdateUser: (id, data) =>
    dispatch(getAdmindashboardUpdateUser(id, data)),
  userActiveRequest: (id, data, showSuccessToast) =>
    dispatch(userActiveRequest(id, data, showSuccessToast)),

  userActiveSuccess: data => dispatch(userActiveSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)
