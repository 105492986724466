import { Checkbox, Col, Row, Select } from "antd"
import React, { useState, useEffect } from "react"
import BoardSelect from "../../../../components/BoardSelect"
import Button from "../../../../components/Button"
import Input from "../../../../components/Input"
import AesPhoneInput from "../../../../components/PhoneInput"
import HomeLayout from "../../../../layout/HomeLayout"
import "./style.scss"
import useForm from "../../../../utils/useForm"
import { postData, postDataSuccess } from "./redux/action"
import { getCountryRequest } from "../../../AuthScreens/SignUp/redux/action"
import { connect } from "react-redux"
import { Toaster } from "react-hot-toast"
// import {backIcon} from "../../../../"
import backIcon from "../../../../assets/images/back-icon-circle.svg"
import { history } from "../../../../reduxStore/store"

const NewFileSite = props => {
  const {
    postData,
    getCountryRequest,
    CountryData,
    PostError,
    PostLoading,
    PostInfo,
    postDataSuccess
  } = props

  const [siteOffers, setSiteOffers] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,

  })

  const { Option } = Select

  const stateSchema = {
    sitename: {
      value: "",
      error: ""
    },
    street: {
      value: "",
      error: ""
    },
    city: {
      value: "",
      error: ""
    },
    zipcode: {
      value: "",
      error: ""
    },
    countryState: {
      value: "",
      error: ""
    },
    country: {
      value: "",
      error: ""
    },
    countrypermit: {
      value: "",
      error: ""
    },
    fieldemail: {
      value: "",
      error: ""
    },
    fieldphonenumber: {
      value: "",
      error: ""
    },
    notes: {
      value: "",
      error: ""
    }
  }
  const validationSchema = {
    sitename: {
      required: false
    },
    street: {
      required: false
    },
    city: {
      required: false
    },
    zipcode: {
      required: false
    },
    countryState: {
      required: false
    },
    country: {
      required: false
    },
    countrypermit: {
      required: false
    },
    fieldemail: {
      required: false
    },
    fieldphonenumber: {
      required: false
    },
    notes: {
      required: false
    }
  }

  const handleFormatChanger = value => {
    if (value.length > 0) {
      handleOnChange("fieldphonenumber", ` +${value}`)
    } else handleOnChange("fieldphonenumber", value)
  }
  const options = CountryData?.map((items, index) => {
    return (
      <Option key={index} value={items.code}>
        {items.name}
      </Option>
    )
  })

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationSchema
  )
  const onSubmit = () => {
    let apiData = {
      email: state.fieldemail.value,
      name: state.sitename.value,
      phone_number: `${state.fieldphonenumber.value}`,
      user_detail: {
        postal_code: state.zipcode.value,
        Country_permit_no: state.countrypermit.value,
        street: state.street.value,
        city: state.city.value,
        state: state.countryState.value,
        country: CountryData[state.country.value]?.id
      },
      material_offer: {
        industrial_land: siteOffers.check1,
        commercial_land: siteOffers.check2,
        residential_land: siteOffers.check3,
        industrial_land_plus: siteOffers.check4,
        note: state.notes.value
      }
    }
    postData(apiData)
  }

  useEffect(() => {
    if (PostInfo?.id) {
      setState(stateSchema)
      setSiteOffers({
        check1: false,
        check2: false,
        check3: false,
        check4: false,


      })
    }
    postDataSuccess(false)
  }, [PostInfo?.id])
  useEffect(() => {
    getCountryRequest("")
  }, [])
  return (
    <HomeLayout dashboardHeader={true}>
      <Toaster />

      <div className="siteContainer">
        <div className="heading">
          <div className="back" onClick={() => history.push("dashboard")}>
            <img src={backIcon} alt="" />
          </div>
          <h1>Add new fill site</h1>
        </div>

        <Row gutter={10}>
          <Col span={8} className="inputWrapper">
            <h2>Fill site name</h2>
            <Input
              placeholder="Enter site name"
              type="text"
              value={state.sitename.value}
              onChange={val => handleOnChange("sitename", val.target.value)}
              showError={true}
              errorMessage={PostError?.name && PostError?.name}
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Street</h2>
            <Input
              placeholder="Enter street"
              type="text"
              value={state.street.value}
              onChange={val => handleOnChange("street", val.target.value)}
              showError={true}
              errorMessage={
                PostError?.user_detail?.street && PostError?.user_detail?.street
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>City</h2>
            <Input
              placeholder="Enter city"
              type="text"
              value={state.city.value}
              onChange={val => handleOnChange("city", val.target.value)}
              showError={true}
              errorMessage={
                PostError?.user_detail?.city && PostError?.user_detail?.city
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>ZIP/Postal code</h2>
            <Input
              placeholder="Enter ZIP/Postal code"
              type="text"
              value={state.zipcode.value}
              onChange={val => handleOnChange("zipcode", val.target.value)}
              showError={true}
              errorMessage={
                PostError?.user_detail?.postal_code &&
                PostError?.user_detail?.postal_code
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>State/Province</h2>
            <Input
              placeholder="Enter State/Povince"
              type="text"
              value={state.countryState.value}
              onChange={val => handleOnChange("countryState", val.target.value)}
              showError={true}
              errorMessage={
                PostError?.user_detail?.state && PostError?.user_detail?.state
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Country</h2>
            <BoardSelect
              options={options}
              placeholder="Select Country"
              onChange={e => {
                handleOnChange("country", e)
              }}
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              optionFilterProp="children"
              value={CountryData[state?.country?.value]?.name}
              showError={true}
              errorMessage={
                PostError?.user_detail?.state && PostError?.user_detail?.country
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Country permit #</h2>
            <Input
              placeholder="Enter number"
              type="text"
              value={state.countrypermit.value}
              onChange={val =>
                handleOnChange("countrypermit", val.target.value)
              }
              showError={true}
              errorMessage={
                PostError?.user_detail?.Country_permit_no &&
                PostError?.user_detail?.Country_permit_no
              }
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Site field email</h2>
            <Input
              placeholder="Enter site field email"
              type="text"
              value={state.fieldemail.value}
              onChange={val => handleOnChange("fieldemail", val.target.value)}
              showError={true}
              errorMessage={PostError?.email && PostError?.email}
            />
          </Col>
          <Col span={8} className="inputWrapper">
            <h2>Site field contact phone</h2>
            <AesPhoneInput
              placeholder="Enter phone"
              type="number"
              value={state.fieldphonenumber.value}
              onChange={handleFormatChanger}
              showError={true}
              errorMessage={PostError?.phone_number && PostError?.phone_number}
            />
          </Col>
        </Row>
        <div className="materialSiteStyle">
          <h1>Material fill site offers</h1>
          <Checkbox
            checked={siteOffers.check3}
            onChange={e =>
              setSiteOffers({ ...siteOffers, check3: e.target.checked })
            }
          >
            Residential Land (RL)
          </Checkbox>
          <Checkbox
            checked={siteOffers.check2}
            onChange={e =>
              setSiteOffers({ ...siteOffers, check2: e.target.checked })
            }
          >
            Commercial Land (CL)
          </Checkbox>
          <Checkbox
            checked={siteOffers.check1}
            onChange={e =>
              setSiteOffers({ ...siteOffers, check1: e.target.checked })
            }
          >
            Industrial Land (IL)
          </Checkbox>
          <Checkbox
            checked={siteOffers.check4}
            onChange={e =>
              setSiteOffers({ ...siteOffers, check4: e.target.checked })
            }
          >
            Industrial Land + (IL+)
          </Checkbox>


        </div>
        {/* <div className="noteAreaStyle">
          <h1>Notes</h1>
          <Input
            placeholder="Type notes"
            type="text"
            className="noteArea"
            value={state.notes.value}
            onChange={val => handleOnChange("notes", val.target.value)}
            showError={true}
            errorMessage={
              PostError?.material_offer?.note && PostError?.material_offer?.note
            }
          />
        </div> */}
        <div className="note-wrapper mt-4">
          <h3 className="note-text">Notes: </h3>
          <textarea
            className="note-textarea"
            // type=""
            type="text"
            placeholder="Enter text"
            value={state.notes.value}
            onChange={val => handleOnChange("notes", val.target.value)}
          // showError={true}
          // errorMessage={state.note.error || error?.note}
          />
          <label
            className={`error-message ${PostError.material_offer ? "opacity-1" : ""
              }`}
          >
            {PostError?.material_offer?.note && PostError?.material_offer?.note}
          </label>
        </div>
        <Button
          title="Save"
          className="SaveBtnStyle"
          onClick={onSubmit}
          showSpinner={PostLoading}
        />
      </div>
    </HomeLayout>
  )
}
const mapStateToProps = state => ({
  CountryData: state.signUp.CountryData,
  PostError: state.NewFileSite.PostError,
  PostLoading: state.NewFileSite.PostLoading,
  PostInfo: state.NewFileSite.PostInfo
})

const mapDispatchToProps = dispatch => ({
  postData: data => dispatch(postData(data)),
  getCountryRequest: data => dispatch(getCountryRequest(data)),
  postDataSuccess: data => dispatch(postDataSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewFileSite)
