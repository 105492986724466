// import Login from "./pages/AuthScreens/Login"
import Landing from "./pages/AuthScreens/Landing/index"
import HomeScreen from "./pages/AuthScreens/HomeScreen"
import SignUp from "./pages/AuthScreens/SignUp"
import LoginScreen from "./pages/AuthScreens/LoginScreen"
import ChangePasword from "./pages/AuthScreens/ChangePasword"
import Dashboard from "./pages/Contractor/Dashboard"
import RequestSoilDeposit from "./pages/Contractor/RequestSoilDeposit"
import MyRequests from "./pages/Contractor/MyRequests"

import { Images } from "./theme/Images"
import DepositSite from "./pages/Contractor/DepositSite"
import SoilAuthorizationForm from "./pages/Contractor/SoilAuthorizationForm"
import MyTeam from "./pages/Contractor/MyTeam"
import AddTeamMember from "./pages/Contractor/AddTeamMember"
import TeamMemberProfile from "./pages/Contractor/TeamMemberProfile"
import AccountInformation from "./pages/Contractor/AccountInformation"
import AccountFillSite from "./pages/Contractor/AccountFillSite"
import AccountSingleSummary from "./pages/Contractor/AccountSingleSummary"

// fillsites
import InvoiceDetails from "./pages/Contractor/InvoiceDetails"
import OwnerMyTeam from "./pages/FillSiteOwner/MyTeam"
import OwnerAddTeamMember from "./pages/FillSiteOwner/AddTeamMember"
import OwnerTeamMemberProfile from "./pages/FillSiteOwner/TeamMemberProfile"
import NotFound from "./pages/NotFound"
import Schedule from "./pages/FillSiteOwner/Schedule"
import ScheduleDetail from "./pages/FillSiteOwner/Schedule/ScheduleDetail"
import FillAccountInformation from "./pages/FillSiteOwner/AccountInformation"
// import InvoiceDetails from "./pages/Constrators/InvoiceDetails"

import Contact from "./pages/VisitingSite/Contact/Contact"
import DepositScreen from "./pages/VisitingSite/DepositScreen"
import Services from "./pages/VisitingSite/Services"
import Home from "./pages/VisitingSite/Home"
import OwnerFillSiteDashbord from "./pages/FillSiteOwner/OwnerFillSiteDashbord"
import RequestList from "./pages/Contractor/RequestList"
import SoilRequest from "./pages/Admin/SoilRequest"
import SoilRequestDetail from "./pages/Admin/SoilRequestDetail"
import SoilRequestConfirmPage from "./pages/Admin/RequestConfirmPage"
import AdminDashboard from "./pages/Admin/AdminDashboard"
import RejectRequestPage from "./pages/Admin/RejectRequestPage"
import NewFileSite from "./pages/Admin/AdminDashboard/NewFileSite"
import EditAndViewUser from "./pages/Admin/EditAndViewUser"
import Invoices from "./pages/Admin/Invoices"

import AdminInvoiceDetails from "./pages/Admin/Invoices/AdminInvoiceDetails"
import DocumentAgreement from "./pages/Admin/DocumentAgreement"
import Reports from "./pages/Admin/Reports"
import ReportDetails from "./pages/Admin/Reports/ReportDetails"
import Notifications from "./pages/Admin/Notifications"
import AdminContractors from "./pages/Admin/AdminContractors"
import AccountRequest from "./pages/Admin/AccountRequest"
import MaterialTracking from "./pages/Admin/MaterialTracking"

import AdminContractorDetail from "./pages/Admin/AdminContractors/AdminContractorDetail"
import ContractorSummaryDetail from "./pages/Admin/AdminContractors/AdminContractorDetail/ContractorSummaryDetail"
export const routes = [
  {
    component: Contact,
    path: "/contact",
    layout: "/visiting"
  },
  {
    component: DepositScreen,
    path: "/soil-deposite-site",
    layout: "/visiting"
  },
  {
    component: Services,
    path: "/services",
    layout: "/visiting"
  },
  {
    component: Home,
    path: "/home",
    layout: "/visiting"
  },

  // {
  //   component: Login,
  //   path: "/login",
  //   layout: "/auth"
  // },

  // dashbord
  {
    name: "Dashboard",
    iconSrc: Images.dashboardIcon,
    activeIconSrc: Images.dashboardActiveIcon,
    component: Dashboard,
    path: "/dashboard",
    layout: "/contractor",
    isShow: "contractor",
    role: "Operator || Manager"
    // isCommon: true,
  },
  // Request Soil Deposit Page
  {
    name: "Request Soil Deposit",
    iconSrc: Images.requestIcon,
    activeIconSrc: Images.requestActiveIcon,
    component: RequestSoilDeposit,
    path: "/request-soil-deposit",
    layout: "/contractor",
    isShow: "contractor",
    role: "Manager"
  },
  // My Request Page
  {
    name: "My Requests",
    iconSrc: Images.myRequestsIcon,
    activeIconSrc: Images.myRequestsActiveIcon,
    component: RequestList,
    path: "/my-requests",
    layout: "/contractor",
    isShow: "contractor",
    role: "Operator || Manager"
  },
  {
    name: "My Requests",
    iconSrc: Images.myRequestsIcon,
    activeIconSrc: Images.myRequestsActiveIcon,
    component: MyRequests,
    path: "/approved-requests",
    layout: "/contractor",
    isShow: false,
    // isOperator:true

    // isCommon: true,
    // isOperator:true

  },
  // My Request Sub Pages
  {
    name: "Deposit Site Screen",
    component: DepositSite,
    path: "/deposit-site",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Soil Authorization Form",
    component: SoilAuthorizationForm,
    path: "/soil-form",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  // Team Members Page
  {
    name: "Team Members",
    iconSrc: Images.teamMembersIcon,
    activeIconSrc: Images.teamMembersActiveIcon,
    component: MyTeam,
    path: "/my-team",
    layout: "/contractor",
    isShow: "contractor"
    // isCommon: true,
  },
  // Team Members Subpage
  {
    name: "Add Team Member",
    component: AddTeamMember,
    path: "/add-member",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Team Member Profile",
    component: TeamMemberProfile,
    path: "/member-profile",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  // Account Overview Page
  {
    name: "Account-Overview",
    iconSrc: Images.accountOverviewIcon,
    activeIconSrc: Images.accountOverviewActiveIcon,
    component: AccountInformation,
    path: "/account_info",
    layout: "/contractor",
    isShow: false,

  },
  // Account Overview Sub Pages
  {
    iconSrc: Images.accountOverviewIcon,
    activeIconSrc: Images.accountOverviewActiveIcon,
    name: "Account Overview",
    component: AccountFillSite,
    path: "/account-overview",
    layout: "/contractor",
    isShow: "contractor",
    // isCommon: true,
  },
  {
    name: "Account Single Summary",
    component: AccountSingleSummary,
    path: "/account-summary",
    layout: "/contractor",
    isShow: false
    // isCommon: true,
  },
  {
    name: "InvoiceDetails",
    component: InvoiceDetails,
    path: "/invoice-Details",
    layout: "/contractor",
    isShow: "/contractor"

    // isCommon: true,
  },
  {
    component: ChangePasword,
    path: "/changePasword",
    layout: "/contractor"
  },

  // fill Site dashbord
  // {
  //   name: "Dashboard",
  //   iconSrc: Images.dashboardIcon,
  //   activeIconSrc: Images.dashboardActiveIcon,
  //   component: OwnerDashbord,
  //   path: "/dashboard",
  //   layout: "/owner",
  //   isShow: false
  //   // isCommon: true,
  // },
  {
    name: "Dashboard",
    iconSrc: Images.dashboardIcon,
    activeIconSrc: Images.dashboardActiveIcon,
    component: OwnerFillSiteDashbord,
    path: "/dashboard",
    layout: "/owner",
    isShow: "owner"

    // isCommon: true,
  },
  {
    name: "Schedule",
    iconSrc: Images.scheduleiconActiveIcon,
    activeIconSrc: Images.scheduleiconIcon,
    component: Schedule,
    path: "/Schedule",
    layout: "/owner",
    isShow: "owner"
    // isCommon: true,
  },
  {
    name: "FillAccountInformation",

    component: FillAccountInformation,
    path: "/Account-information",
    layout: "/owner",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Schedule Detail",
    component: ScheduleDetail,
    path: "/ScheduleDetail",
    layout: "/owner",
    isShow: false
    // isCommon: true,
  },
  // {
  //   name: "Team Members",
  //   iconSrc: Images.teamMembersIcon,
  //   activeIconSrc: Images.teamMembersActiveIcon,
  //   component: OwnerMyTeam,
  //   path: "/my_team",
  //   layout: "/owner",
  //   isShow: "owner"
  //   // isCommon: true,
  // },

  // Team Members Subpage
  {
    name: "Add Team Member",
    component: OwnerAddTeamMember,
    path: "/add-member",
    layout: "/owner",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Team Member Profile",
    component: OwnerTeamMemberProfile,
    path: "/member-profile",
    layout: "/owner",
    isShow: false
    // isCommon: true,
  },
  {
    component: ChangePasword,
    path: "/changePasword",
    layout: "/owner"
  },
  {
    component: NotFound,
    path: "/notfound"

    // isCommon: true,
  },
  // Admin Routing
  {
    name: "Dashboard - Fill Sites",
    iconSrc: Images.dashboardIcon,
    activeIconSrc: Images.dashboardActiveIcon,
    component: AdminDashboard,
    path: "/dashboard",
    layout: "/admin",
    isShow: "admin",
    role: "Operator || Manager"

    // isCommon: true,
  },
  {
    name: "Contractors",
    iconSrc: Images.contractorUnactive,
    activeIconSrc: Images.contractor,
    component: AdminContractors,
    path: "/Admin-Contractors",
    layout: "/admin",
    isShow: "admin",
    // role: "Operator || Manager"

    // isCommon: true,
  },
  {
    name: "Contractor Detail",
    component: AdminContractorDetail,
    path: "/admin-contractor-detail",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Contractor Summary Detail",
    component: ContractorSummaryDetail,
    path: "/contractor-summary-detail",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Dashboard - Add New Fill Sites",
    component: EditAndViewUser,
    path: "/update-fill-site",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },

  {
    name: "Dashboard - Add New Fill Sites",
    component: NewFileSite,
    path: "/new-file-site",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },

  // {
  //   name: "Contractors",
  //   iconSrc: Images.dashboardIcon,
  //   activeIconSrc: Images.dashboardActiveIcon,
  //   component: AdminDashboard,
  //   path: "/contractors",
  //   layout: "/admin",
  //   isShow: "admin"
  //   // isCommon: true,
  // },
  // {
  //   name: "Material Tracking",
  //   iconSrc: Images.dashboardIcon,
  //   activeIconSrc: Images.dashboardActiveIcon,
  //   component: AdminDashboard,
  //   path: "/dashboard",
  //   layout: "/admin",
  //   isShow: "admin"
  //   // isCommon: true,
  // },
  {
    name: "Material Tracking",
    iconSrc: Images.MaterialTracking,
    activeIconSrc: Images.MaterialTrackingactive,
    component: MaterialTracking,
    path: "/material_tracking",
    layout: "/admin",
    isShow: "admin",
    // isCommon: true,
  },
  {
    name: "Soil Authorization Form",
    component: ContractorSummaryDetail,
    path: "/soil-authorization-form",
    layout: "/admin",
    isShow: false
    // isCommon: true,
  },
  {
    name: "Soil Deposit Requests",
    iconSrc: Images.requestIcon,
    activeIconSrc: Images.requestActiveIcon,
    component: SoilRequest,
    path: "/soil-request",
    layout: "/admin",
    isShow: "admin",
    role: "Operator || Manager"
  },
  {
    name: "Contractor Account Requests",
    iconSrc: Images.AccountRequest,
    activeIconSrc: Images.AccountRequestActive,
    component: AccountRequest,
    path: "/account-request",
    layout: "/admin",
    isShow: "admin",
    role: "Operator || Manager"
  },
  {
    name: "Invoice",
    iconSrc: Images.myRequestsIcon,
    activeIconSrc: Images.myRequestsActiveIcon,
    component: Invoices,
    path: "/invoice",
    layout: "/admin",
    isShow: "admin",
    role: "Operator || Manager"
    // isCommon: true,
  },
  // {
  //   name: "Invoices Information",
  //   iconSrc: Images.requestIcon,
  //   activeIconSrc: Images.requestActiveIcon,
  //   component: InvoicesInformation,
  //   path: "/invoicesInformation",
  //   layout: "/admin",
  //   isShow: "admin"
  // },
  {
    name: "Admin Invoice Details",

    component: AdminInvoiceDetails,
    path: "/admin-invoice-details",
    layout: "/admin",
    isShow: false,
    role: "Operator || Manager"
  },

  {
    name: "Soil Request Detail",
    component: SoilRequestDetail,
    path: "/soil-request-detail",
    layout: "/admin",
    isShow: false,
    role: "Operator || Manager"
  },
  {
    name: "Soil Request Confirm",
    component: SoilRequestConfirmPage,
    path: "/soil-request-confirm",
    layout: "/admin",
    isShow: false,
    role: "Operator || Manager"
  },
  {
    name: "Reject Soil Request",
    component: RejectRequestPage,
    path: "/soil-request-reject",
    layout: "/admin",
    isShow: false,
    role: "Operator || Manager"
  },
  {
    name: "Team Members",
    iconSrc: Images.teamMembersIcon,
    activeIconSrc: Images.teamMembersActiveIcon,
    component: MyTeam,
    path: "/my-team",
    layout: "/admin",
    isShow: "admin",
    // role:"Operator || Manager"
    // isCommon: true,
  },
  // Team Members Subpage
  {
    name: "Add Team Member",
    component: AddTeamMember,
    path: "/add-member",
    layout: "/admin",
    isShow: false,
    // role:"Operator || Manager"
    // isCommon: true,
  },
  {
    name: "Team Member Profile",
    component: TeamMemberProfile,
    path: "/member-profile",
    layout: "/admin",
    isShow: false,
    // role:"Operator || Manager"
    // isCommon: true,
  },
  {
    name: "Documents and Agreements",
    iconSrc: Images.DocumentAgreementActive,
    activeIconSrc: Images.DocumentAgreement,
    component: DocumentAgreement,
    path: "/Document-Agreement",
    layout: "/admin",
    isShow: "admin",
    // role: "Operator || Manager"

    // isCommon: true,
  },
  {
    name: "Notifications",
    iconSrc: Images.bellActive,
    activeIconSrc: Images.bell,
    component: Notifications,
    path: "/Notifications",
    layout: "/admin",
    isShow: "admin",
    // role: "Operator || Manager"

    // isCommon: true,
  }, {
    name: "Reports",
    iconSrc: Images.reports,
    activeIconSrc: Images.activeReport,
    component: Reports,
    path: "/Reports",
    layout: "/admin",
    isShow: "admin",
    // role: "Operator || Manager"

    // isCommon: true,
  },
  {
    name: "Reports",
    // iconSrc: Images.DocumentAgreementActive,
    // activeIconSrc: Images. DocumentAgreement,
    component: ReportDetails,
    path: "/Report-Details",
    layout: "/admin",
    // isShow: "admin",
    // role: "Operator || Manager"

    // isCommon: true,
  },
  // auth Screen
  // {
  //   component: Landing,
  //   path: "/landing",
  //   layout: "/auth"
  // },
  {
    component: HomeScreen,
    path: "/account",
    layout: "/auth"
  },
  {
    component: SignUp,
    path: "/sign-up",
    layout: "/auth"
  },
  {
    component: LoginScreen,
    path: "/login",
    layout: "/auth"
  },
  {
    component: ChangePasword,
    path: "/changePasword",
    layout: "/auth"
  }
]
