import React, { useEffect } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import Button from "../../../components/Button"

import backIcon from "../../../assets/images/back-icon-circle.svg"
import uploadFile from "../../../assets/images/uploadfile.svg"

import "./style.scss"
import { connect } from "react-redux"
import {
  getAdminApprovedRequest,
  putAdminSoilRequest
} from "../SoilRequest/redux/action"
import { Spinner } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"

const SoilRequestDetail = props => {
  const {
    AdminApprovedRequestdata,
    putAdminSoilRequest,
    loginInfo,
    requesting,
    getAdminApprovedRequest
  } = props

  let savedMaterialId = localStorage.getItem("materialId")

  const handleAproved = e => {
    history.push({
      pathname: `soil-request-confirm`,
      id: savedMaterialId
    })
  }
  useEffect(() => {
    getAdminApprovedRequest(`${savedMaterialId}/`)
  }, [])

  const handleRegect = e => {
    history.push("soil-request-reject")
  }
  const isLastItem = (currIndex, list) =>
    !(currIndex === list?.length - 1) && <>,</>

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        {requesting ? (
          <div className="soil-request-detail-section">
            <div className="body-detail bodyScroll">
              <div className="contractor-company">
                <Skeleton width="40%" />
                <div className="company-info" style={{ gap: "0px" }}>
                  <Skeleton width="50%" />
                  <div className="contact">
                    <div className="address mt-3">
                      <Skeleton width="70%" />
                      <Skeleton width="50%" />
                      <Skeleton width="70%" />
                      <Skeleton width="50%" />
                    </div>
                  </div>
                  <div className="material-info mt-3">
                    <Skeleton width="70%" />
                    <Skeleton width="50%" />
                    <Skeleton width="70%" />
                  </div>
                  {/* <div className="material-analytical-data">
                    <Skeleton width="90%" />
               
                  </div> */}
                  <div className="material-analytical-data mt-3">
                    <div className="data-title">
                      <Skeleton width="100%" />
                    </div>
                  </div>
                </div>
                <div className="notes-wrapper">
                  <h3 className="notes-heading">
                    <Skeleton width="50%" />
                  </h3>
                  <p className="notes-body">
                    <Skeleton width="30%" />
                  </p>
                </div>
              </div>
              {/* <div className="button-wrapper"> */}
              <div className="mt-5 mb-5">
                <Skeleton width="50%" />
              </div>
              {/* </div>
               */}
            </div>
          </div>
        ) : (
          <section className="soil-request-detail-section">
            <div className="heading-detail">
              <div className="back" onClick={() => history.goBack()}>
                <img src={backIcon} alt="" />
              </div>
              <div className="contractor-name">
                <h1>Request Details</h1>
              </div>
            </div>
            <div className="body-detail bodyScroll">
              <div className="contractor-company">
                <h3>{AdminApprovedRequestdata?.user?.company?.company_name}</h3>
              </div>
              <div className="d-flex justify-content-between">
                <div className="contact">
                  <div className="address">
                    <p>
                      <strong>Address: </strong>
                      {AdminApprovedRequestdata.user?.billing?.street},{" "}
                      {
                        AdminApprovedRequestdata?.user?.billing
                          ?.city_postal_code
                      }
                      , {AdminApprovedRequestdata?.user?.billing?.province},{" "}
                      {AdminApprovedRequestdata?.user?.billing?.country_name}
                    </p>
                  </div>
                  <div className="email">
                    <strong>Email: </strong>
                    {AdminApprovedRequestdata?.user?.email}
                  </div>
                  <div className="phone">
                    <strong>Phone: </strong>
                    {AdminApprovedRequestdata.user?.phone_number}
                  </div>
                  <div className="position">
                    <strong>Position: </strong>
                    {
                      AdminApprovedRequestdata.user?.company
                        ?.position_in_company
                    }
                  </div>
                </div>
                <div className="material-info">
                  <div className="material-po-number">
                    <strong>My Earth Network PO#: </strong>
                    {AdminApprovedRequestdata?.aes_po_no || ""}
                  </div>

                  <div className="material-type">
                    {AdminApprovedRequestdata?.deposit_request?.slice(0, 4).map(
                      (material, index) => (
                        <>
                          <div className="d-flex" style={{ gap: 10 }}>
                            <strong> Material: </strong>

                            <div>
                              {material?.material_name?.name}&nbsp;|&nbsp;
                              {material.material_type == 1
                                ? "Structural"
                                : "Non-Structural"}{" "}
                              &nbsp;|&nbsp;{material?.material_amount}{" "}
                              {material?.material_mass_type === "1" ? (
                                <>mt </>
                              ) : (
                                <span>
                                  m<sup>3</sup>
                                </span>
                              )}
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </div>


                  {/* <div className="d-flex" style={{ gap: 10 }}>
                    <strong>Material amount: </strong>{" "}
                    <div className="material-amount">
                      {AdminApprovedRequestdata?.deposit_request
                        ?.slice(0, 6)
                        .map((material, index) => (
                          <>
                            {material?.material_amount}
                            &nbsp;
                            {material?.material_mass_type === "1" ? (
                              <>
                                mt{" "}
                                {isLastItem(
                                  index,
                                  AdminApprovedRequestdata?.deposit_request
                                )}
                              </>
                            ) : (
                              <span>
                                m<sup>3</sup>
                                {isLastItem(
                                  index,
                                  AdminApprovedRequestdata?.deposit_request
                                )}{" "}
                              </span>
                            )}
                          </>
                        ))}
                      {AdminApprovedRequestdata?.deposit_request?.length > 8 &&
                        " . . ."}
                    </div>
                  </div> */}
                  {/* <div className="structural">
                    <strong>Structural/Non-Structuralfff: </strong>
                    {
                      AdminApprovedRequestdata.deposit_request[0].material_type == 1
                        ? "Structural"
                        : "Non-Structural"
                    }
                  </div> */}
                </div>
                <div className="material-analytical-data">
                  <div className="data-title ">
                    <strong>
                      Enviroment / geotechnical analytical :
                    </strong>

                      { ( AdminApprovedRequestdata?.environmental &&
                        AdminApprovedRequestdata?.environmental.length > 0 ) &&
                        <div className="img-bg"> {
                          AdminApprovedRequestdata.environmental.map(
                            (file, index) => (
                              <a key={index} href={file.environmental} download target="blank">
                                <span  >
                                  <img src={uploadFile} alt="Upload File" />
                                </span>
                              </a>
                            )
                          ) 
                        }
                     
                        </div>
                        }
                    
                  </div>
                </div>
              </div>
              <div className="notes-wrapper">
                <h3 className="notes-heading">Notes:</h3>
                <p className="notes-body">
                  {/* {AdminApprovedRequestdata?.note} */}
                  <div
                    style={{ height: 70, overflow: "auto" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        AdminApprovedRequestdata &&
                        AdminApprovedRequestdata?.note?.replace(/\r\n/g, "<br>")
                    }}
                  ></div>
                </p>
              </div>
              <div className="button-wrapper">
                {AdminApprovedRequestdata?.status == "pending" ? (
                  <>
                    <Button
                      title="Approve"
                      className="aes-btn orange-btn"
                      onClick={() => {
                        handleAproved()
                      }}
                    />
                    <Button
                      title="Reject"
                      className="aes-btn danger-transparent-btn"
                      onClick={() => handleRegect()}
                    />
                  </>
                ) : (
                  <h3 style={{ textTransform: "capitalize" }}>
                    Status: {AdminApprovedRequestdata?.status || ""}
                  </h3>
                )}
              </div>
            </div>
          </section>
        )}
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => {
  // debugger

  return {
    loginInfo: state.login.loginInfo,

    AdminApprovedRequestdata: state.AdminSoilRequest.AdminApprovedRequestdata,

    requesting: state.AdminSoilRequest.requesting
  }
}

const mapDispatchToProps = dispatch => ({
  putAdminSoilRequest: (data, id) => dispatch(putAdminSoilRequest(data, id)),
  getAdminApprovedRequest: (data, id) =>
    dispatch(getAdminApprovedRequest(data, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(SoilRequestDetail)
