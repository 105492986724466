import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import Button from "../../../components/Button"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import UploadFile from "../../../components/UploadFile"

import "./style.scss"
import { Select, Space } from "antd"
import Input from "../../../components/Input"
import useForm from "../../../utils/useForm"
import {
  postSoilAcceptRequest,
  postSoilAcceptFailure,
  getAdminFillRequest
} from "./redux/action"
import { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import AeSelect from "../../../components/AeSelect"
import { getAdminApprovedRequest } from "../SoilRequest/redux/action"
import { Col } from "react-bootstrap"

const SoilRequestConfirmPage = props => {

  const {
    postSoilAccept,
    postSoilAcceptRequest,
    error,
    postSoilAcceptFailure,
    getAdminFillRequest,
    selectData,
    putSoildata,
    postSoilAcceptRequesting,
    putAdminSoilSuccess
  } = props
  const { AdminApprovedRequestdata, } = useSelector(
    state => state.AdminSoilRequest

  )
  const stateSchema = {
    Originatinglocation: {
      value: "",
      error: ""
    },
    CSRClassification: {
      value: "",
      error: ""
    },
    laboratoryfilenumber: {
      value: "",
      error: ""
    },
    qep_signature: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    Originatinglocation: {
      required: true
    },
    CSRClassification: {
      required: true
    },
    laboratoryfilenumber: {
      required: true
    },
    qep_signature: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const [selectedFile, setSelectedFile] = useState(false)
  const [selectedFile2, setSelectedFile2] = useState(false)
  const [adminData, setAdminData] = useState(false)

  const handlefile1 = e => {
    const file = e.target.files[0]
    const fileType = file.type
    const fileSize = file.size

    // Check if file type is PDF
    if (fileType !== "application/pdf") {
      alert("Please upload a PDF file.")
      return
    }

    // Check if file size is less than or equal to 50MB
    if (fileSize > 50 * 1024 * 1024) {
      alert("Please upload a file smaller than 50MB.")
      return
    }

    setSelectedFile(file)
  }

  const handlefile = e => {
    const file = e.target.files[0]
    const fileType = file.type
    const fileSize = file.size

    // Check if file type is PDF
    if (fileType !== "application/pdf") {
      alert("Please upload a PDF file.")
      return
    }

    // Check if file size is less than or equal to 50MB
    if (fileSize > 50 * 1024 * 1024) {
      alert("Please upload a file smaller than 50MB.")
      return
    }

    setSelectedFile2(file)
  }
  const savedMaterialId = localStorage.getItem("materialId")
  const [adminId, setAdminId] = useState([])
  const [search, setSearch] = useState()

  useEffect(() => {
    getAdminFillRequest()
  }, [])

  useEffect(() => {
    getAdminUser()
  })

  const { Option } = Select
  const handleChange = (id, value) => {
    setSearch(value)
    setAdminId(id)
  }

  function getAdminUser() {
    if (selectData) {
      selectData.map(data => {
        setAdminData(data)
      })
    }
  }
  useEffect(() => {
    setState(stateSchema)
    postSoilAcceptFailure(false)
  }, [])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAdminApprovedRequest(`${savedMaterialId}/`))
  }, [])

  useEffect(() => {
    if (AdminApprovedRequestdata) {
      handleOnChange("Originatinglocation", AdminApprovedRequestdata?.originating_location)
      handleOnChange("laboratoryfilenumber", AdminApprovedRequestdata?.laboratory_file)
    }
  }, [AdminApprovedRequestdata])

  const submit = async () => {
    const formData = new FormData()
    formData.append("csr_classification", state.CSRClassification.value)
    formData.append("laboratory_file_no", state.laboratoryfilenumber.value)
    formData.append("originating_location", state.Originatinglocation.value)
    formData.append("qep_signature", state.qep_signature.value)
    formData.append("deposit_request", savedMaterialId)
    formData.append("fill_site_owner", JSON.stringify(adminId))
    formData.append("status", "accepted")
    formData.append("soil_authorization_form", selectedFile)
    formData.append("Contaminated_site_regulations", selectedFile2)
    await postSoilAcceptRequest(formData)
  }
  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="soil-request-confirm-section">
          <div className="heading-confirm">
            <div className="back" onClick={() => history.goBack()}>
              <img src={backIcon} alt="" />
            </div>
            <div className="contractor-name">
              <h3>Approval</h3>
            </div>
          </div>
          <div className="body-confirm bodyScroll">
            <div className="report-detail-form">
              <Input
                placeholder="Enter laboratory file number "
                label="Laboratory file #: "
                type="text"
                showLabel={true}
                showError={true}
                disabled={true}
                errorMessage={error?.laboratory_file_no}
                value={state.laboratoryfilenumber.value}
                onChange={val =>
                  handleOnChange("laboratoryfilenumber", val.target.value)
                }
              />
              <Input
                placeholder="Enter originating location"
                label="Originating location: "
                showLabel={true}
                showError={true}
                disabled={true}
                // onFocus={ postSoilAcceptFailure(false)}
                errorMessage={
                  state.Originatinglocation.error || error?.originating_location
                }
                value={state.Originatinglocation.value}
                onChange={val =>
                  handleOnChange("Originatinglocation", val.target.value)
                }
              />

              <Input
                placeholder="Enter classification"
                label="CSR Classification: "
                showLabel={true}
                showError={true}
                errorMessage={
                  state.CSRClassification.error || error?.csr_classification
                }
                value={state.CSRClassification.value}
                onChange={val =>
                  handleOnChange("CSRClassification", val.target.value)
                }
              />

              <div className="fill-site-select">
                <p>Tag fill site:</p>
                <Select
                  placeholder="Select fill site"
                  maxTagCount="responsive"
                  mode="multiple"
                  style={{
                    width: "100%",
                    height: 47
                  }}
                  value={search}
                  filterOption={(inputValue, option) =>
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) !== -1
                  }
                  // label={adminData?.username}
                  onChange={handleChange}
                >
                  {selectData ? (
                    selectData.map(data => (
                      <Option
                        key={data.id}
                        value={data.id}
                        label={data.username}
                      >
                        <Space>{data.username}</Space>
                      </Option>
                    ))
                  ) : (
                    <Option value={null} disabled>
                      No data
                    </Option>
                  )}
                </Select>

                <label
                  className={`error-message  ${error && error?.fill_site_owner ? "opacity-1" : ""
                    }`}
                >
                  {error && error?.fill_site_owner}
                </label>
              </div>
            </div>
            <hr />
            <div className="doc-wrapper">
              <div className="document">
                <div className="logo-wrapper">
                  <div className="logo-text">
                    <UploadFile
                      title={
                        selectedFile?.name
                          ? selectedFile.name
                          : "Upload Soil Authorization form"
                      }
                      onChange={handlefile1}
                    />
                  </div>
                </div>
                <div className="logo-wrapper">
                  <div className="logo-text">
                    <UploadFile
                      title={
                        selectedFile2?.name
                          ? selectedFile2.name
                          : "Upload Contaminated Site Regulations"
                      }
                      onChange={handlefile}
                    />
                  </div>
                </div>
              </div>
              <div className="error-flex">
                <label
                  className={`error-message ${error?.soil_authorization_form ? "opacity-1" : ""
                    }`}

                >
                  {error?.soil_authorization_form &&
                    error?.soil_authorization_form}
                </label>
                <label
                  className={`error-message ${error?.Contaminated_site_regulations ? "opacity-1" : ""
                    }`}

                >
                  {error?.Contaminated_site_regulations &&
                    error?.Contaminated_site_regulations}
                </label>
              </div>
              <div span={8} className="inputWrapper col-lg-3">

                <Input
                  placeholder="Enter the QEP name"
                  label="QEP Signature (required)"
                  className="mt-2"
                  showLabel={true}
                  value={state.qep_signature.value}
                  showError={true}
                  errorMessage={error?.qep_signature || ""}
                  onChange={val =>
                    handleOnChange("qep_signature", val.target.value)
                  }
                />
              </div>
              <div className="confirm-btn">
                <Button
                  title="Confirm"
                  className="aes-btn orange-btn"
                  onClick={() => submit()}
                  showSpinner={postSoilAcceptRequesting}
                />
              </div>
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  postSoilAccept: state.SoilConfirm.postSoilAccept,
  postSoilAcceptRequesting: state.SoilConfirm.postSoilAcceptRequesting,
  error: state.SoilConfirm.error,
  selectData: state.SoilConfirm.getAdminFillSite
})

const mapDispatchToProps = dispatch => ({
  postSoilAcceptRequest: data => dispatch(postSoilAcceptRequest(data)),
  postSoilAcceptFailure: data => dispatch(postSoilAcceptFailure(data)),

  getAdminFillRequest: data => dispatch(getAdminFillRequest(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SoilRequestConfirmPage)
