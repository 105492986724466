import React, { useEffect, useRef, useState } from 'react'
import HomeLayout from '../../../layout/HomeLayout'
import './style.scss'
import { Col, Row } from 'antd'
import Button from '../../../components/Button'
import { getMaterialTrackingRequest } from './redux/action'
import { useDispatch, useSelector } from "react-redux"
import Skeleton from 'react-loading-skeleton'
import Filter from '../../../components/Filter'
import moment from 'moment'
import { history } from '../../../reduxStore/store'
import Search from '../../../components/Search'

const MaterialTracking = props => {
    const [filterState, setfilterState] = useState(false)
    const [trackingDate, setTrackingDate] = useState(false)
    const ref = useRef()

    const dispatch = useDispatch()

    const {
        MaterialTrackingData,
        requesting
    } =
        useSelector(state => state.MaterialTracking)

    useEffect(() => {
        dispatch(getMaterialTrackingRequest(""))
    }, [])

    const dateChange = (_, dateString, type) => {
        setfilterState(false)
        setTrackingDate(dateString)
        if (type == "date") {
            dispatch(getMaterialTrackingRequest(`?date=${dateString}`))
        }
    }


    const onfillSiteSearch = e => {
        dispatch(getMaterialTrackingRequest(`?name=${e.target.value}`))
    }

    const ContractorLocation = (location, city, province, country) => {
        const CLDate = []
        location && CLDate.push(location)
        city && CLDate.push(city)
        province && CLDate.push(province)
        country && CLDate.push(country)
        return CLDate?.join(" , ")
    }

    return (
        <HomeLayout dashboardHeader={true}>
            <section className="content-section materialtracting-section">
                <div className="headingStyle">
                    <span
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexGrow: "1",
                            alignItems: "baseline"
                        }}
                    >
                        {trackingDate ?
                            <h1 className="w-100">Material tracking on this day /
                                <span style={{ fontWeight: '300' }}> {moment(trackingDate).format('MMMM Do')}</span>
                            </h1>
                            :
                            <h1 className="w-100">Material Tracking</h1>
                        }
                    </span>
                    <form>
                        <Search
                            placeholder="Search by fill site"
                            onChange={e => {
                                onfillSiteSearch(e)
                            }}
                        />
                    </form>
                    <div ref={ref}>
                        <Filter
                            onDateChange={dateChange}
                            onClick={() => setfilterState(!filterState)}
                            calenderState={filterState}
                            dataData={{ fromDate: trackingDate }}
                            datType={"date"}
                            disabledDate={(current) => current.isAfter(moment().subtract(0, "day"))}
                        />
                    </div>

                </div>
                <div className="bodyScroll">
                    {requesting ?
                        <>
                            {[...Array(2)].map((item) => (
                                <div className='customCollapse'>
                                    <div className='collapse-Header d-flex justify-content-between align-items-center'>
                                        <h2 className='d-flex gap-3'>
                                            <Skeleton width={200} height={10} /> / <Skeleton width={150} height={10} />
                                        </h2>
                                        <div className='aes-btn transparent-btn'>
                                            <Skeleton width={100} height={10} />
                                        </div>
                                    </div>
                                    <Row className='profile-info'>
                                        <Col md={12} >
                                            <h3 className='title d-flex align-items-center flex-wrap gap-2'>Contractor name: <span><Skeleton width={200} height={10} />  </span> </h3>
                                        </Col>
                                        <Col md={12} >
                                            <h3 className='title d-flex align-items-center flex-wrap gap-2'>Contractor location: <span> <Skeleton width={200} height={10} /> </span> </h3>
                                        </Col>
                                    </Row>
                                    <Row className='profile-info'>
                                        <Col md={12} >
                                            <h3 className='title d-flex align-items-center flex-wrap gap-2'>Number of truck: <span> <Skeleton width={200} height={10} /> </span> </h3>
                                        </Col>
                                        <Col md={12} >
                                            <h3 className='title d-flex align-items-center flex-wrap gap-2'>Total Amount of Load: <span> <Skeleton width={200} height={10} /> </span> </h3>
                                        </Col>
                                    </Row>
                                    <Row className='truckId'>
                                        <Col span={24} className='mb-2 d-flex justify-content-between align-items-center'><h4>Truck Details:</h4>      <div className='aes-btn transparent-btn'>
                                            <Skeleton width={100} height={10} />
                                        </div></Col>
                                        {[...Array(3)].map((item, index) => (
                                            <Col span={24} className='mb-3' key={index}>
                                                <div className='d-flex gap-3 align-items-center'>
                                                    <h5> <Skeleton width={100} height={10} /> </h5>
                                                    <Skeleton width={200} height={10} />
                                                    <Skeleton width={200} height={10} />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>

                                </div>
                            ))}
                        </>
                        :
                        <>
                            {MaterialTrackingData?.length ?
                                <>
                                    {MaterialTrackingData?.map((item, index) => {
                                        return (
                                            <div className='customCollapse' key={index}>
                                                <div className='collapse-Header d-flex justify-content-between align-items-center'>
                                                    <h2>
                                                        {item?.fill_site} {item?.fill_site_location.location ? ' / ' : ''}
                                                        {item?.fill_site_location.location && <span>
                                                            {ContractorLocation(
                                                                item?.fill_site_location?.location,
                                                                item?.fill_site_location?.city,
                                                                item?.fill_site_location?.province,
                                                                item?.fill_site_location?.country,
                                                            )}
                                                        </span>}
                                                        <span class="badge rounded-pill text-white ms-2" style={{background: '#0A5F59'}}>{item?.status}</span>
                                                    </h2>
                                                    {/* <Button
                                                        className="aes-btn transparent-btn"
                                                        title={item?.status}
                                                    /> */}
                                                </div>
                                                <Row className='profile-info'>
                                                    <Col md={12} >
                                                        <h3 className='title d-flex align-items-center flex-wrap gap-2'>Contractor name: <span>{item?.contactor_name}</span> </h3>
                                                    </Col>
                                                    <Col md={12} >
                                                        <h3 className='title d-flex align-items-center flex-wrap gap-2'>Contractor location:
                                                            <span>
                                                                {ContractorLocation(
                                                                    item?.contactor_location?.location,
                                                                    item?.contactor_location?.city,
                                                                    item?.contactor_location?.province,
                                                                    item?.contactor_location?.country,
                                                                )}
                                                            </span>
                                                        </h3>
                                                    </Col>
                                                </Row>
                                                <Row className='profile-info'>
                                                    <Col md={12} >
                                                        <h3 className='title d-flex align-items-center flex-wrap gap-2'>Number of truck: <span>{item?.no_of_truck}</span> </h3>
                                                    </Col>
                                                    <Col md={12} >
                                                        <h3 className='title d-flex align-items-center flex-wrap gap-2'>Total Amount of Load: <span>{item?.total_amount_of_load}</span> </h3>
                                                    </Col>
                                                </Row>
                                                <Row className='truckId'>
                                                    <Col span={24} className='mb-2 d-flex justify-content-between align-items-center'>
                                                        <h4>Truck Details:</h4>
                                                        <Button
                                                            className="aes-btn transparent-btn"
                                                            title="Soil Authorization Form"
                                                            onClick={() => {
                                                                history.push("soil-authorization-form",
                                                                    {
                                                                        id: item?.request_availability_id,
                                                                        name: "Soil Authorization Form"
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                    </Col>
                                                    {item.truck_list.length ?
                                                        <Col span={24} className='mb-3' key={index}>
                                                            <Row className='gap-3 align-items-center'>
                                                                <Col span={5} className='d-flex flex-column gap-2'>
                                                                    <label>ID</label>

                                                                </Col>
                                                                <Col span={5} className='d-flex flex-column gap-2'>
                                                                    <label>Company</label>

                                                                </Col>
                                                                <Col span={5} className='d-flex flex-column gap-2'>
                                                                    <label>Config</label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        :
                                                        ''
                                                    }
                                                    {item.truck_list.map((item, index) => (
                                                        <Col span={24} className='mb-3' key={index}>
                                                            <Row className='d-flex gap-3 align-items-center'>
                                                                <Col span={5} className='d-flex flex-column gap-2'>
                                                                    <h5>{item?.licence_plate}</h5>
                                                                </Col>
                                                                <Col span={5} className='d-flex flex-column gap-2'>
                                                                    <h5>{item?.truck_company}</h5>
                                                                </Col>
                                                                <Col span={5} className='d-flex flex-column gap-2'>
                                                                    <h5>{item?.truck_config}</h5>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    ))}
                                                </Row>

                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div className='nodatafound'>
                                    No Record Found
                                </div>
                            }
                        </>
                    }
                </div>
            </section>
        </HomeLayout >
    )
}

export default MaterialTracking