import React, { useEffect } from "react"
import "./style.scss"
import HomeLayout from "../../../../layout/HomeLayout"
import { useDispatch, useSelector } from "react-redux"
import backIcon from "../../../../assets/images/back-icon-circle.svg"
import { history } from "../../../../reduxStore/store"
import { Toaster } from "react-hot-toast"
import { Col, Row } from "antd"
import Button from "../../../../components/Button"
import { getContractorDetail } from "../redux/action"
import moment from "moment"
import Skeleton from "react-loading-skeleton"

const AdminContractorDetail = props => {

  const { location } = props

  const id = location.state.id
  const dispatch = useDispatch()

  const {
    adminContractorDetailData,
    adminContractorDetailRequesting
  } =
    useSelector(
      state => state.adminContractor
    )

  const Data = adminContractorDetailData
  const DetailData = adminContractorDetailData?.load_detail

  useEffect(() => {
    dispatch(getContractorDetail(id))
  }, [])

  const GetDetailTime = (val) => {
    const DDate = new Date(val)
    const DetailDate = moment(DDate).format("DD MMM")
    const DetailTime = moment(DDate).format("LT")
    return (
      <div className="dateStyle">
        {DetailDate} / <span>{DetailTime}</span>
      </div>
    )
  }

  const Loader = () => {
    return (
      <div className="">
        <Row className="contentStyle">
          <Col span={17} className="border-bottom pb-4">
            <Skeleton width="20%" height="20px" />
          </Col>
          <Col
            span={7}
            className="text-end border-bottom pb-4"
          >
            <Skeleton width="20%" height="30px" />
          </Col>
          <Col span={12} className="border-bottom py-2">
            <Skeleton width="50%" />
          </Col>
          <Col span={12} className="border-bottom py-2">
            <Skeleton width="50%" />
          </Col>
          <Col span={12} className="border-bottom py-2">
            <Skeleton width="60%" />
          </Col>
          <Col span={12} className="border-bottom py-2">
            <Skeleton width="60%" />
          </Col>
          <Col span={24} className="border-bottom py-2">
            <Skeleton width="35%" />
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <HomeLayout dashboardHeader={true}>
      <Toaster />
      <section className="content-section adminContractorDetail-section">
        <div className="headingStyle">
          <div className="back" onClick={() => history.goBack()}>
            <img src={backIcon} alt="" />
          </div>
          <h1 className="w-100">Contractor Detail</h1>
        </div>

        {adminContractorDetailRequesting ?
          <Loader />
          :
          <Row className="contentStyle">
            <span className="border-bottom w-100 d-md-flex">
              <Col md={12} sm={24} className=' pb-4'>
                <h1 className="heading-name">
                  {Data?.company?.company_name ? Data?.company?.company_name : "---"}
                </h1>
              </Col>
              <Col md={12} sm={24} className="text-end pb-4">
                <span className="BtnsStyle">
                  {Data.user_status ? "Active" : "Inactive"}
                  </span>
              </Col>
            </span>
            <Col sm={12} xs={24} className="border-bottom py-2">
              <h2>
                Emai:{" "}
                <span>{Data?.email ? Data?.email : "---"}</span>
              </h2>
            </Col>
            <Col sm={12} xs={24} className="border-bottom py-2">
              <h2>
                Position in company:{" "}
                <span>
                  {Data?.company?.position_in_company
                    ? Data?.company?.position_in_company
                    : "---"}
                </span>
              </h2>
            </Col>
            <Col sm={12} xs={24} className="border-bottom py-2">
              <h2>
                Location:{" "}
                <span>
                  <span>
                    {(Data?.billing?.street &&
                      Data?.billing?.street) ||
                      "--"}
                    ,
                    {(Data?.billing?.city &&
                      Data?.billing?.city) ||
                      "--"}
                    ,
                    {(Data?.billing?.province &&
                      Data?.billing?.province) ||
                      "--"}
                    ,
                    {(Data?.billing?.city_postal_code &&
                      Data?.billing?.city_postal_code) ||
                      "--"}
                  </span>
                </span>
              </h2>
            </Col>
            <Col sm={12} xs={24} className="border-bottom py-2">
              <h2>
                CC Email:{" "}
                <span>
                  {Data?.billing?.cc_email
                    ? Data?.billing?.cc_email
                    : "---"}
                </span>
              </h2>
            </Col>
            <Col sm={12} xs={24} className="py-2">
              <h2>
                Phone number:{" "}
                <span>
                  {Data?.phone_number && !isNaN(Number(Data?.phone_number.slice(1)))
                    ? Data?.phone_number
                    : "---"}
                </span>
              </h2>
            </Col>
          </Row>
        }
        <h1 className="detailTitle">Load details:</h1>
        {adminContractorDetailRequesting ?
          <Loader />
          :
          DetailData?.length ?
            DetailData?.map((item, index) => {
              return (
                <Row className="content-container" key={index}>
                  <span className="border-bottom w-100 d-md-flex">
                    <Col md={12} xs={24} className="pb-3">
                      {GetDetailTime(item?.time || new Date())}
                    </Col>
                    <Col md={12} xs={24} className="text-end pb-3">
                      <Button
                        title="See Summary Details"
                        className="summaryBtn"
                        onClick={() => history.push("contractor-summary-detail", { id: item?.request_availability })}
                      />
                    </Col>
                  </span>
                  <Col lg={24} xs={24} className="py-3 border-bottom">
                    <h1 className="detailLabel">Fill site name: <span>{item?.fill_site_name || "---"}</span></h1>
                  </Col>
                  <Col lg={24} xs={24} className="py-3 border-bottom">
                    <h1 className="detailLabel">Number of trucks: <span>{item?.number_of_truck || 0}</span></h1>
                  </Col>
                  <Col lg={24} xs={24} className="py-3 border-bottom">
                    <h1 className="detailLabel">Load amount: <span>{item?.load_amount || 0}</span></h1>
                  </Col>
                  <Col lg={24} xs={24} className="py-3 border-bottom">
                    <h1 className="detailLabel">Material:
                      <span> {item?.material?.length ? item?.material?.join(" , ") : "--"}</span>
                    </h1>
                  </Col>
                  <Col lg={24} xs={24} className="py-3 border-bottom">
                    <h1 className="detailLabel">Reports: <span>{item?.report || "---"}</span></h1>
                  </Col>
                </Row>
              )
            }
            )
            : <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "30vh",
                background: "#ffffff",
                borderRadius: "4px",
              }}
            >
              <p>No Data Found</p>
            </div>
        }
      </section>
    </HomeLayout >
  )
}

export default AdminContractorDetail
