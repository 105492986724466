import { Col, Row, Space, Table } from "antd"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Button from "../../../../components/Button"
import HomeLayout from "../../../../layout/HomeLayout"
import Breadcrumb from "../../../../CommonComponents/Breadcrumb/breadcrumb"
import "./style.scss"
import {
  getScheduleRequest,
  patchSoilAuthorization,
  patchSoilAuthorizationSuccess
} from "../redux/action"
import { connect } from "react-redux"
import Skeleton from "react-loading-skeleton"
import { CheckOutlined } from "@ant-design/icons"
import moment from "moment"

const ScheduleDetail = props => {
  const {
    scheduleId,
    getScheduleRequest,
    patchSoilAuthorization,
    patchSoilLoad,
    scheduleData,
    requesting,
    patchSoil,
    patchSoilSuccess
  } = props

  const history = useHistory()

  const [index, setIndex] = useState(0)

  useEffect(() => {
    getScheduleRequest(scheduleId)
  }, [])

  useEffect(() => {
    if (patchSoil?.id) {
      getScheduleRequest(scheduleId)
      patchSoilSuccess(false)
    }
  }, [patchSoil?.id])

  const patchSoilRequest = (id, ind) => {
    setIndex(ind)
    const data = {
      status: "confirm"
    }
    patchSoilAuthorization(id, data)
  }

  const columns = [
    {
      title: "License plate",
      dataIndex: "license_plate",
      key: "license_plate",
      render: item => {
        return (

          <Space
            size="middle"
            className="full-name"
            title={item.length >= 20 ? item : null}
            style={{ cursor: item?.length >= 20 ? "pointer" : "auto" }}
          >
            {item && item?.length >= 20
              ? item.slice(0, 17) + "..."
              : item}
          </Space>
        )
      }
    },
    {
      title: "Truck configuration",
      dataIndex: "truck_config",
      key: "truck_config",
      render: truck_config => {
        return (
          <Space
            size="middle"
            className="full-name"
            title={truck_config.length >= 20 ? truck_config : null}
            style={{ cursor: truck_config?.length >= 20 ? "pointer" : "auto" }}
          >
            {truck_config && truck_config?.length >= 20
              ? truck_config.slice(0, 17) + "..."
              : truck_config}
          </Space>
        )
      }
    },
    {
      title: "Company name",
      dataIndex: "company_name",
      key: "company_name",
      render: company_name => {
        return (
          <Space
            size="middle"
            className="full-name"
            title={company_name.length >= 20 ? company_name : null}
            style={{ cursor: company_name?.length >= 20 ? "pointer" : "auto" }}
          >
            {company_name && company_name?.length >= 20
              ? company_name.slice(0, 17) + "..."
              : company_name}
          </Space>
        )
      }
    },
    {
      title: "Leave Time",
      dataIndex: "leave_time",
      key: "leave_time",
      render: leave_time => {
        return (
          <>
            <span style={{ fontWeight: "600" }}>
              {moment(leave_time).format("DD MMM")}
            </span>{" "}
            /{" "}
            <span style={{ fontWeight: "500" }}>
              {moment(leave_time).format("LT")}
            </span>
          </>
        )
      }
    },

    {
      title: "Confirm Arrival",
      dataIndex: "status",
      key: "status",
      render: (status, item, ind) => {
        return (
          <>
            {status == "pending" ? (
              <Button
                title="Confirm arrival"
                className="btnStyle"
                onClick={() => patchSoilRequest(item?.id, ind)}
                showSpinner={index == ind && patchSoilLoad}
              />
            ) : (
              <>
                <span style={{ fontWeight: "600" }}>
                  {moment(item.confirm_arrival).format("DD MMM")}
                </span>{" "}
                /{" "}
                <span style={{ fontWeight: "500" }}>
                  {moment(item.confirm_arrival).format("LT")}
                </span>{" "}
              </>
            )}
          </>
        )
      }
    }
  ]
  // console.log("scheduleData",scheduleData.contractor_detail.company_name[0].company_name

  // );
  return (
    <HomeLayout dashboardHeader={true}>
      <section className="content OwnerDashbord">
        <div className="ScheduleContainer">
          <div className="titleStyle">
            <Breadcrumb onClick={() => history.goBack()} />
            <h1> Soil Authorization Form Details</h1>
          </div>
          {!scheduleData && requesting ? (
            <>
              <div className="detailConatiner">
                <Skeleton width="30%" />
                <Row className="submitStyle" gutter={10}>
                  <Col span={8} className="mt-3">
                    <Skeleton width="70%" />
                    <Skeleton width="40%" />

                    <Skeleton width="70%" />

                    <Skeleton width="40%" />
                  </Col>
                  <Col span={8} className="mt-3">
                    <span className="mt-3">
                      <Skeleton width="70%" />
                    </span>
                    <span className="mt-3">
                      <Skeleton width="40%" />
                    </span>
                    <span className="mt-3">
                      <Skeleton width="70%" />
                    </span>{" "}
                    <span className="mt-3">
                      <Skeleton width="40%" />
                    </span>
                  </Col>
                  <Col span={8} className="mt-3">
                    <Skeleton width="40%" />
                  </Col>
                </Row>
                <div className="mt-3">
                  <Skeleton width="40%" />
                </div>

                <Row className="trackingStyle" gutter={10}>
                  <>
                    <Col span={8} className="mt-3">
                      <Skeleton width="70%" />
                    </Col>
                    <Col span={8} className="mt-3">
                      <Skeleton width="70%" />
                    </Col>
                    <Col span={8} className="mt-3">
                      <Skeleton width="70%" />
                    </Col>
                  </>
                </Row>
                <div className="mt-3">
                  <Skeleton width="50%" />
                </div>
              </div>
            </>
          ) : (
            <div className="detailConatiner">
              <h1>
                {scheduleData.contractor_detail &&
                  scheduleData.contractor_detail?.company_name[0]?.company_name}
              </h1>
              <Row className="submitStyle" gutter={10}>
                <Col span={8}>
                  <h2>
                    Address:{" "}
                    <span>{scheduleData?.contractor_detail?.address}</span>
                  </h2>
                  <h2>
                    Email: <span>{scheduleData?.contractor_detail?.email}</span>
                  </h2>
                  <h2>
                    Phone: <span>{scheduleData?.contractor_detail?.phone}</span>
                  </h2>
                  <h2>
                    Posrition:{" "}
                    <span>{scheduleData?.contractor_detail?.position}</span>
                  </h2>
                </Col>
                <Col span={8}>
                  <h2>
                    Originating location:{" "}
                    <span>{scheduleData?.admin_detail?.ori_location}</span>
                  </h2>
                  <h2>
                    My Earth Network PO#: <span>{scheduleData?.admin_detail?.aes_po}</span>
                  </h2>
                  <h2>
                    CSR: <span>{scheduleData?.admin_detail?.csr}</span>
                  </h2>
                  <h2>
                    Laboratory file #:{" "}
                    <span>{scheduleData?.admin_detail?.lab_file}</span>
                  </h2>
                </Col>
                <Col span={8}>
                  <h2>
                    Receiving site:{" "}
                    <span>{scheduleData?.site_detail?.name}</span>
                  </h2>
                </Col>
              </Row>
              <h1>Trucking information:</h1>
              {!scheduleData && requesting ? (
                <Table
                  rowKey="key"
                  pagination={false}
                  dataSource={[...Array(5)].map((_, index) => ({
                    key: `key${index}`
                  }))}
                  columns={columns.map(column => {
                    return {
                      ...column,
                      render: function renderPlaceholder() {
                        return <Skeleton />
                      }
                    }
                  })}
                />
              ) : (
                <Table
                  className="trackingStyle"
                  columns={columns}
                  dataSource={scheduleData?.trucking_detail}
                  pagination={false}
                  rowKey="id"
                  scroll={{ y: 560 }}
                />
              )}
              <Row className="btnContainer">
                <Col>
                  <h2>
                    Q.E.P. <span>{scheduleData?.qep_signature || ""}</span>
                  </h2>
                  {/* {scheduleData.status === "pending" ? (
                    <Button
                      title="Confirm Trucks Arrival"
                      className="btnStyle"
                      onClick={patchSoil}
                      showSpinner={patchSoilLoad}
                    />
                  ) : (
                    <h3>Status: {scheduleData.status}</h3>
                  )} */}
                </Col>
              </Row>
            </div>
          )}
        </div>
      </section>
    </HomeLayout>
  )
}
const mapStateToProps = state => ({
  scheduleData: state.schedule.data,
  scheduleId: state.login.scheduleId,
  requesting: state.schedule.requesting,
  patchSoilLoad: state.schedule.patchSoilLoad,
  patchSoil: state.schedule.patchSoil
})

const mapDispatchToProps = dispatch => ({
  getScheduleRequest: filterModel => dispatch(getScheduleRequest(filterModel)),
  patchSoilAuthorization: (id, data) =>
    dispatch(patchSoilAuthorization(id, data)),
  patchSoilSuccess: data => dispatch(patchSoilAuthorizationSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDetail)
