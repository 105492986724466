import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import { GET_MATERIALS_REQUEST, POST_REQUEST_SOIL_DEPOSIT } from "./type"
// actions
import {
  soilDepositRequest,
  soilDepositRequestSuccess,
  soilDepositRequestFailure,
  getMaterialSuccess,
  getMaterialFailure
} from "./action"
import toast from "react-hot-toast"

async function soilDepositRequestAPI(data) {
  const URL = `${BASE_URL}material/material/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* soilDepositRequestInfo({ data ,clearData}) {
  try {
    const response = yield call(soilDepositRequestAPI,data)
    yield put(soilDepositRequestSuccess(response?.data))
    yield put(clearData())
  
  } catch (e) {
    const { response } = e
    if(response?.status == 400 && response?.data?.msg){
      toast.error(response?.data?.msg)
    }
    yield put(soilDepositRequestFailure(response?.data))
  }
}

const MaterialDataAPI = async () => {
  const URL = `${BASE_URL}material/materialname/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* MaterialData() {
  try {
    const response = yield call(MaterialDataAPI)
    yield put(getMaterialSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getMaterialFailure(response.data))
  }
}

export default all([
  takeLatest(POST_REQUEST_SOIL_DEPOSIT, soilDepositRequestInfo),
  takeLatest(GET_MATERIALS_REQUEST, MaterialData)
])
