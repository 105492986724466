import React, { useEffect } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"
import uploadfile from "../../../assets/images/uploadfile.svg"
import Button from "../../../components/Button"
import { connect } from "react-redux"
import { history } from "../../../reduxStore/store"
import { getIdRequest } from "../RequestList/redux/action"
import moment from "moment"
import {
  setDepositeSiteID,
  setBookID
} from "../../AuthScreens/LoginScreen/redux/action"
import backIcon from "../../../assets/images/back-icon-circle.svg"

const MyRequests = props => {
  const {
    myReguestData,
    setBookID,
    getIdRequest,
    seeMoreID,
    setDepositeSiteID
  } = props

  useEffect(() => {
    if (seeMoreID) {
      getIdRequest(seeMoreID)
    }
  }, [seeMoreID])

  const onSeeAvailability = (item, depReq) => {
    if ((item?.book_id, item?.book_status)) {
      setBookID(item?.book_id)
      history.push("soil-form")
    } else {
      if ((item.id, depReq)) {
        setDepositeSiteID({ id: item.id, depReq: depReq })
        history.push("deposit-site")
      }
    }
  }

  const addressCreate = (adress) => {
    if (adress?.length) {
      return adress?.map(item => (
        <span>
          {`${item?.street ? item?.street + ',' : ''} ${item?.city ? item?.city + ',' : ''} ${item?.province ? item?.province + ',' : ''} ${item?.city_postal_code || ''}`}
        </span>
      ))
    }
  }

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content Request-section">
          <div className="headerText">
            <div className="back" onClick={() => history.goBack()}>
              <img src={backIcon} alt="" />
            </div>
            <h1>Your Request is approved</h1>
          </div>
          <div className="main-div">
            <div className="card p-0 requestCard">
              <h3>Request details:</h3>
              <div className="right-text">
                <p>
                  Request Submitted on:
                  <span>
                    {moment(myReguestData?.soil_request?.created_at).format(
                      "MMM DD, YYYY "
                    ) || ""}
                  </span>
                </p>
              </div>
              <div className="right-text">
                <p>
                  Request Approved on:
                  <span>
                    {moment(myReguestData?.soil_request?.updated_at).format(
                      "MMM DD, YYYY "
                    ) || ""}
                  </span>
                </p>
              </div>
            </div>
            <div className="card p-0 requestCard">
              <h3> List of all material needed:</h3>
              {myReguestData?.soil_request?.material_need?.map(
                (item, index) => (
                  <div className="right-text">
                    <p>
                      CSR/Amount:
                      <>
                        <span key={index}>
                          {item.material_name?.name} /{item.material_amount}
                          &nbsp;
                          {item.material_mass_type == 1 ? (
                            "Metric Tonnes"
                          ) : (
                            <span>
                              m<sup>3</sup>
                            </span>
                          )}
                        </span>
                      </>
                    </p>
                  </div>
                )
              )}
              <div className="right-text pt-1 pb-1 ">
                <p>
                  Environmental / geotechnical analytical data:
                  {myReguestData?.soil_request?.material_need &&
                    myReguestData?.soil_request?.material_need[0]?.environmental
                      .length > 0 &&
                    myReguestData?.soil_request?.material_need[0]?.environmental.map(
                      (file, index) => (
                        <>
                          <a key={index} href={file.environmental} download target="blank">
                            <span style={{ marginRight: 10 }}>
                              <img src={uploadfile} alt="Upload File" />
                            </span>
                          </a>
                        </>
                      )
                    )}
                </p>
              </div>
              <div className="right-text border-0">
                <p>
                  Structural/Non-Structural:
                  {myReguestData?.soil_request?.material_need?.map(
                    (item, index) => (
                      <>
                        <span>
                          {item.material_type == 1
                            ? "Structural"
                            : "Non-Structural"}
                        </span>
                        {index !==
                          myReguestData?.soil_request?.material_need.length -
                          1 && " , "}
                      </>
                    )
                  )}
                </p>
              </div>
            </div>
            {/* ))} */}
            <div className="wrap-text">
              <div>
                {myReguestData?.fill_site?.csr_acceptance ? (
                  <div className="wrap-text1">
                    <p>{myReguestData?.fill_site?.csr_acceptance}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {myReguestData?.fill_site &&
              myReguestData?.fill_site_owner?.map(item => (
                <div className="card requestCard p-0  ">
                  <div className="button-wraper">
                    <h3 className="border-0 m-0 mt-1">
                      {item?.user?.name || ""}
                    </h3>
                    <Button
                      onClick={() =>
                        onSeeAvailability(
                          item,
                          myReguestData?.fill_site?.deposit_request
                        )
                      }
                      title="See Availability"
                      className="aes-btn "
                    />
                  </div>
                  <div className="right-text">
                    <p>
                      Operation hours:
                      <span>{`${item?.owner_schedule[0]?.working_time_from
                        ? item?.owner_schedule[0]?.working_time_from
                        : "--"
                        } - 
                      ${item?.owner_schedule[0]?.working_time_to
                          ? item?.owner_schedule[0]?.working_time_to
                          : "--"
                        }
                       `}</span>
                    </p>
                  </div>
                  <div className="right-text ">
                    <p>
                      Address:
                      {addressCreate(item?.fill_site_info)}
                    </p>
                  </div>
                  <div className="right-text ">
                    <p>
                      Max allowable trucks per day:
                      <span>
                        {item?.owner_schedule[0]?.max_truck_per_day || "--"}
                      </span>
                    </p>
                  </div>
                  <div className="right-text ">
                    <p>
                      Max allowable loads per day:
                      <span>
                        {item?.owner_schedule[0]?.max_load_per_day || "--"}
                      </span>
                    </p>
                  </div>
                  <div className="right-text border-0 ">
                    <p>
                      CSR acceptance:
                      <span>{item?.csr}</span>
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </section>
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => ({
  myReguestData: state.RequestData.updateData,
  seeMoreID: state.login.seeMoreID
})

const mapDispatchToProps = dispatch => ({
  getIdRequest: data => dispatch(getIdRequest(data)),
  setDepositeSiteID: data => dispatch(setDepositeSiteID(data)),
  setBookID: data => dispatch(setBookID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyRequests)
