import React, { useEffect, useState } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import Button from "../../../components/Button"

import "./style.scss"
import Search from "../../../components/Search"
import { Table } from "antd"
import { connect } from "react-redux"
import { getAdminApprovedRequest } from "./redux/action"
import Skeleton from "react-loading-skeleton"

const SoilRequest = props => {
  const { getAdminApprovedRequest, AdminApprovedRequestdata, requesting } =
    props
  const [tabledata, setTabledata] = useState([])
  useEffect(() => {
    getAdminApprovedRequest("")
  }, [])
  const loadingData = new Array(10).fill().map(() => ({ loading: true }))

  useEffect(() => {
    if (AdminApprovedRequestdata && AdminApprovedRequestdata.length > 0) {
      const tableData = AdminApprovedRequestdata.map(item => ({
        key: item.id,
        aes_po_no: item.aes_po_no,
        contractor_name: item.user?.company?.company_name || "",
        material_type: item,
        status : item.status,
        material_amount: item
      }))
      setTabledata(tableData)
    } else {
      setTabledata([])
    }
  }, [AdminApprovedRequestdata])

  const columns = [
    {
      title: "My Earth Network PO#",
      dataIndex: "aes_po_no",
      key: "aes_po_no",
      render: (text, { loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else if (text) {
          return <div>{text}</div>
        } else {
          return <div>--</div>
        }
      }
    },
    {
      title: "Company Name",
      dataIndex: "contractor_name",
      key: "contractor_name",
      render: (text, { loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else if (text.length) {
          return <div className="contractor-name">{text}</div>
        } else {
          return <div className="contractor-name">--</div>
        }
      }
    },
    {
      title: "Material Name",
      dataIndex: "material_type",
      key: "material_type",
      render: (_, { material_type, loading }) => {
        if (loading) {
          return <Skeleton width="40%" />
        } else if (material_type?.deposit_request?.length) {
          return (
            <div
              className="column"
              style={{ cursor: "pointer" }}
              title={material_type?.deposit_request

                ?.map(item => item.material_name?.name)
                .join(", ")}
            >
              <>
                {material_type?.deposit_request
                  .slice(0, 5)
                  ?.map(item => item.material_name?.name)
                  .join(", ")}
                {material_type?.deposit_request.length > 6 && " . . ."}
              </>
             
            </div>
          )
        } else {
          return <div className="column">--</div>
        }
      }
    },
    {
      title: "Material Amount",
      key: "material_amount",
      render: (_, { material_amount, loading }) => {
        if (loading) {
          return <Skeleton width="50%" />
        } else if (material_amount?.deposit_request?.length) {
          // debugger
          return (
            <>
              {material_amount?.deposit_request
                .slice(0, 5)
                .map((request, index) => (
                  <span key={index}

                  >
                    {request.material_amount}


                    {request.material_mass_type === "1" ? (
                      "mt"
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{ __html: "m<sup>3</sup>" }}
                      />
                    )}
                    {index !== material_amount?.deposit_request.length - 1 &&
                      ", "}
                  </span>
                ))}
              {material_amount?.deposit_request.length > 6 && ". . ."}
            </>
          )
        } else {
          return <span>--</span>
        }
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, { loading }) => {
      
        if (loading) {
          return <Skeleton width="40%" />
        } else if (text) {
          return <div>{text}</div>
        } else {
          return <div>--</div>
        }
      }
    },
    {
      title: " ",
      key: "action",
      render: (_, { material_amount, loading }) => {
        if (loading) {
          return <Skeleton width="100%" />
        } else {
          return (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                title="See Details"
                className="aes-btn transparent-btn "
                onClick={() => {
                  const materialId = material_amount.id

                  localStorage.setItem("materialId", materialId)

                  const savedMaterialId = localStorage.getItem("materialId")
                  getAdminApprovedRequest(`${savedMaterialId}/`)

                  history.push("soil-request-detail")
                }}
              />
            </div>
          )
        }
      }
    }
  ]
  let timeoutId
  const contractorSearch = e => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      getAdminApprovedRequest(`?name=${e.target.value}`)
    }, 1000)
  }
  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content-section soil-deposite-request-section">
          <div className="heading-request">
            <h1>Soil Deposit Requests</h1>
            <form>
              <Search
                placeholder="Search by contractor"
                onChange={e => {
                  contractorSearch(e)
                }}
              />
            </form>
          </div>

          <div className="soil-request-table-wrapper bodyScroll">
            <Table
              dataSource={requesting ? loadingData : tabledata}
              columns={columns}
              pagination={false}
              scroll={{
                y: 500
              }}
            />
          </div>
        </section>
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => {
  return {
    AdminApprovedRequestdata: state.AdminSoilRequest.AdminApprovedRequestdata,
    requesting: state.AdminSoilRequest.requesting
  }
}

const mapDispatchToProps = dispatch => ({
  getAdminApprovedRequest: data => dispatch(getAdminApprovedRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SoilRequest)
