import React, { useEffect } from "react"
import HomeLayout from "../../../../../layout/HomeLayout"
import { history } from "../../../../../reduxStore/store"
import backIcon from "../../../../../assets/images/back-icon-circle.svg"
import "./style.scss"
import { Skeleton, Space, Table } from "antd"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { getAvailability } from "../../../../Contractor/SoilAuthorizationForm/redux/action"

const ContractorSummaryDetail = ({ location }) => {

  const id = location.state.id
  const name = location.state.name
  const dispatch = useDispatch()
  const { availabilityData } = useSelector(state => state.SoilAuthorizationForm)

  useEffect(() => {
    dispatch(getAvailability(id))
  }, [])


  const columns = [
    {
      title: "License plate",
      dataIndex: "license_plate",
      key: "license_plate",
      render: item => {
        return (
          <Space
            size="middle"
            className="full-name"
            style={{ cursor: "pointer" }}
            title={item?.length >= 17 ? item : null}
          >
            {item && item?.length >= 17 ? item.slice(0, 17) + "..." : item}
          </Space>
        )
      }
    },
    {
      title: "Truck configuration",
      dataIndex: "truck_config",
      key: "truck_config",
      render: truck_config => {
        return (
          <Space
            size="middle"
            className="full-name"
            style={{ cursor: "pointer" }}
            title={truck_config.length >= 17 ? truck_config : null}
          >
            {truck_config && truck_config?.length >= 17
              ? truck_config.slice(0, 17) + "..."
              : truck_config}
          </Space>
        )
      }
    },
    {
      title: "Company name",
      dataIndex: "company_name",
      key: "company_name",
      render: company_name => {
        return (
          <Space
            size="middle"
            className="full-name"
            style={{ cursor: "pointer" }}
            title={company_name.length >= 20 ? company_name : null}
          >
            {company_name && company_name?.length >= 20
              ? company_name.slice(0, 20) + "..."
              : company_name}
          </Space>
        )
      }
    },
    {
      title: "Leave Time",
      dataIndex: "leave_time",
      key: "leave_time",
      render: leave_time => {
        return (
          <>
            <span style={{ fontWeight: "600" }}>
              {moment(leave_time).format("DD MMM")}
            </span>{" "}
            /{" "}
            <span style={{ fontWeight: "500" }}>
              {moment(leave_time).format("LT")}
            </span>
          </>
        )
      }
    },

    {
      title: "Confirm Arrival",
      dataIndex: "status",
      key: "status",
      render: (status, item, ind) => {
        return (
          <>
            {status == "pending" ? (
              <>On the way</>
            ) : (
              <>
                <span style={{ fontWeight: "600" }}>
                  {moment(item.confirm_arrival).format("DD MMM")}
                </span>{" "}
                /{" "}
                <span style={{ fontWeight: "500" }}>
                  {moment(item.confirm_arrival).format("LT")}
                </span>{" "}
              </>
            )}
          </>
        )
      }
    }
  ]

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content-section soil-form-section">
          <div className="soil-form-content-section">
            <div className="heading-soil-form">
              <div className="back" onClick={() => {
                history.goBack()
              }}>
                <img src={backIcon} alt="" />
              </div>
              <h1>{name ? name : "Summary Details"}</h1>
            </div>
            <div className="soil-authorization-form">
              <div className="container">
                <div className="row top-row">
                  <div className="col-md-4 details-wrapper">
                    <div className="details">
                      Address:
                      <span>
                        {availabilityData?.contractor_detail?.address || ""}
                      </span>
                    </div>
                    <div className="details">
                      Email:
                      <span>
                        {availabilityData?.contractor_detail?.email || ""}
                      </span>
                    </div>
                    <div className="details">
                      Phone:
                      <span>
                        {availabilityData?.contractor_detail?.phone || ""}
                      </span>
                    </div>
                    <div className="details">
                      Position:
                      <span>
                        {availabilityData?.contractor_detail?.position || ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 details-wrapper">
                    <div className="details">
                      Originating location:
                      <span>
                        {availabilityData?.admin_detail?.ori_location || ""}
                      </span>
                    </div>
                    <div className="details">
                      My Earth Network PO#:
                      <span>
                        {availabilityData?.admin_detail?.aes_po || ""}
                      </span>
                    </div>
                    <div className="details">
                      CSR:
                      <span>{availabilityData?.admin_detail?.csr || ""}</span>
                    </div>
                    <div className="details">
                      Laboratory file #:
                      <span>
                        {availabilityData?.admin_detail?.lab_file || ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 details-wrapper">
                    <div className="details">
                      Receiving site:
                      <span>{availabilityData?.site_detail?.name || ""}</span>
                    </div>
                  </div>
                </div>
                <div className="row bottom-row">
                  <div className="col-md-12 form-heading">
                    Trucking information:
                  </div>
                  {!availabilityData ? (
                    <Table
                      rowKey="key"
                      pagination={false}
                      dataSource={[...Array(5)].map((_, index) => ({
                        key: `key${index}`
                      }))}
                      columns={columns.map(column => {
                        return {
                          ...column,
                          render: function renderPlaceholder() {
                            return <Skeleton />
                          }
                        }
                      })}
                    />
                  ) : (
                    <Table
                      className="trackingStyle"
                      columns={columns}
                      dataSource={availabilityData?.trucking_detail}
                      pagination={false}
                      rowKey="id"
                      scroll={{ y: 560 }}
                    />
                  )}
                </div>
                <div className="row button-row">
                  <div className="col-md-4 left-wrapper">
                    <div className="details">
                      <h3>Q.E.P.</h3>
                      <div className="field">
                        {availabilityData?.qep_signature || ""}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-4 right-wrapper">
                    <div className="button-wrapper">
                      <Button
                        title="Save and Send to the Side Filed"
                        onClick={onSaveSend}
                        showSpinner={postSaveSendLoad}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}

export default ContractorSummaryDetail
