import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getInvoiceRequestSucess,
  getInvoiceRequestFailure,
  getSingleInvoiceRequestSucess,
  getSingleInvoiceRequestFailure,
  patchInvoiceStatusSucess,
  patchInvoiceStatusFailure
} from "./action"
import {
  GET_INVOICE_DATA,
  GET_SINGLE_INVOICE_DATA,
  PATCH_INVOICE_STATUS
} from "./type"
import { toast } from "react-hot-toast"

const InvoicesAPI = async (name = "") => {
  const URL = `${BASE_URL}api/v1/admin/invoice/${name}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* InvoicesData({ data }) {
  try {
    const response = yield call(InvoicesAPI, data)
    yield put(getInvoiceRequestSucess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getInvoiceRequestFailure(response.data))
  }
}

const SingleInvoicesAPI = async id => {
  const URL = `${BASE_URL}api/v1/admin/invoice/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* SingleInvoicesData({ data }) {
  try {
    const response = yield call(SingleInvoicesAPI, data)
    yield put(getSingleInvoiceRequestSucess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getSingleInvoiceRequestFailure(response.data))
  }
}

const patchInvoicesAPI = async (id = "", data) => {
  const URL = `${BASE_URL}api/v1/admin/update_invoice_status/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* patchInvoicesData({ id, data }) {
  try {
    const response = yield call(patchInvoicesAPI, id, data)
    yield put(patchInvoiceStatusSucess(response.data))
    toast.success("Status Updated Successfully")
  } catch (e) {
    const { response } = e
    yield put(patchInvoiceStatusFailure(response.data))
  }
}

export default all([
  takeLatest(GET_INVOICE_DATA, InvoicesData),
  takeLatest(GET_SINGLE_INVOICE_DATA, SingleInvoicesData),
  takeLatest(PATCH_INVOICE_STATUS, patchInvoicesData)
])
