import React, { useState, useEffect } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import Search from "../../../components/Search"
import filter from "../../../assets/images/filter.svg"
import "./style.scss"
import { Menu, Dropdown, Table, Space } from "antd"
import Button from "../../../components/Button"
import { getInvoiceRequest } from "./redux/action"
import { connect } from "react-redux"
import { history } from "../../../reduxStore/store"
import { setInvoiceId } from "../../AuthScreens/LoginScreen/redux/action"
import Skeleton from "react-loading-skeleton"

const Invoices = props => {
  const { getInvoiceRequest, invoiceData, setInvoiceId, requesting } = props

  const [status, setStatus] = useState("Status")

  useEffect(() => {
    getInvoiceRequest("")
  }, [])

  const handleMenuClick = ({ key }) => {
    setStatus(key);

    const isActive = key === "Active" ? true : false;

    getInvoiceRequest(`?status=${isActive}`);

  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Active">Active</Menu.Item>
      <Menu.Item key="Blocked">Blocked</Menu.Item>
    </Menu>
  )

  const seeMore = id => {
    if (id) {
      setInvoiceId(id)
      history.push("admin-invoice-details")
    }
  }

  const columns = [
    {
      title: "Contractors name:",
      dataIndex: "contractor_name",
      key: "contractor_name",
      render: contractor_name =>
        !(contractor_name == null) ? contractor_name : <span>---</span> // using == for auto type coercion of undefined to null as well
    },

    {
      title: "Total invoices:",
      dataIndex: "total_invoice",
      key: "total_invoice",
      align: 'center',
      render: total_invoice => (

        <Space
          size="middle"
          className="contact-phone"
        // style={{display:"flex",justifyContent:"end"}}
        >
          {!(total_invoice == null) ? total_invoice : <span>---</span>}
        </Space>

      )
    },
    {
      title: "Paid invoices",
      dataIndex: "paid_invoices",
      key: "paid_invoices",
      align: 'center',
      render: paid_invoices => (

        <Space
          size="middle"
          className="contact-phone"
        // style={{display:"flex",justifyContent:"end"}}
        >
          {!(paid_invoices == null) ? paid_invoices : <span>---</span>}
        </Space>

      )
    },
    {
      title: "Outstanding invoices:",
      dataIndex: "outstanding_invoice",
      key: "outstanding_invoice",
      align: 'center',
      render: outstanding_invoice => (

        <Space
          size="middle"
          className="contact-phone"
        // style={{display:"flex",justifyContent:"end"}}
        >
          {!(outstanding_invoice == null) ? outstanding_invoice : <span>---</span>}
        </Space>

      )
    },
    {
      title: "Status:",
      dataIndex: "status",
      key: "status",

      render: status => (

        <Space
          size="middle"
          className="contact-phone"
        // style={{display:"flex",justifyContent:"end"}}
        >
          {status ? (
            <div className={status == "Blocked" ? "status-column" : ""}>
              {status}
            </div>
          ) : (
            <span>---</span>
          )
          }
        </Space>
      )
    },
    {
      title: "Action",
      render: (_, { contractor_id }) => (
        <Button
          className="aes-btn transparent-btn "
          title="See Details"
          onClick={() => seeMore(contractor_id)}
        />
      )
    }
  ]

  const onSearch = val => {
    getInvoiceRequest(`?name=${val}`)
  }

  return (
    <HomeLayout dashboardHeader={true}>
      <section className="content-section add-team-members-section">
        <div className="add-team-members-content-section">
          <div className="heading-add-team-members">
            <h1>Invoices Information</h1>
          </div>
          {requesting ? (
            <div className="header-box">
              <div className="box">
                <h1>
                  <Skeleton width="10%" />
                </h1>
                <p>
                  <Skeleton width="40%" />
                </p>
              </div>
              <div className="box">
                <h1>
                  <Skeleton width="10%" />
                </h1>
                <p>
                  <Skeleton width="40%" />
                </p>
              </div>
              <div className="box">
                <h1>
                  <Skeleton width="10%" />
                </h1>
                <p>
                  <Skeleton width="40%" />
                </p>
              </div>
            </div>
          ) : (
            <div className="header-box">
              <div className="box">
                <h1>{invoiceData?.total_invoices || "0"}</h1>
                <p>Total Invoices</p>
              </div>
              <div className="box">
                <h1>{invoiceData?.paid_invoices || "0"}</h1>
                <p>Paid Invoices</p>
              </div>
              <div className="box">
                <h1>{invoiceData?.outstanding_invoice || "0"}</h1>
                <p>Outstanding Invoices</p>
              </div>
            </div>
          )}

          <div className="searchBar">
            <Search onChange={val => onSearch(val.target.value)} />
            <Dropdown overlay={menu} trigger={["click"]}>
              <div className="button-filter">
                <img src={filter} />
                <p>
                  Filter by:
                  <span>{status}</span>
                </p>
              </div>
            </Dropdown>
          </div>
          <div>
            {requesting ? (
              <Table
                rowKey="key"
                pagination={false}
                dataSource={[...Array(10)].map((_, index) => ({
                  key: `key${index}`
                }))}
                columns={columns.map(column => {
                  return {
                    ...column,
                    render: function renderPlaceholder() {
                      return <Skeleton width="20%" />
                    }
                  }
                })}
              />
            ) : (
              <Table
                className="invoice-table"
                dataSource={invoiceData?.invoice_list}
                columns={columns}
                pagination={false}
                scroll={{
                  y: 500
                }}
              />
            )}
          </div>
        </div>
      </section>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  invoiceData: state.Invoices.invoiceData,
  requesting: state.Invoices.requesting
})

const mapDispatchToProps = dispatch => ({
  getInvoiceRequest: data => dispatch(getInvoiceRequest(data)),
  setInvoiceId: data => dispatch(setInvoiceId(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
