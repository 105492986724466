import React, { useEffect, useState } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import { history } from "../../../reduxStore/store"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import addIcon from "../../../assets/images/plus.svg"
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import "./style.scss"
import { connect, useSelector } from "react-redux"
import { Col, Row, Skeleton, Space, Table } from "antd"
import {
  postSaveSendRequest,
  getAvailability,
  postSaveSendRequestSuccess,
  postSaveSendRequestFailure
} from "./redux/action"
import { CheckOutlined } from "@ant-design/icons"
import moment from "moment"
import { toast } from "react-hot-toast"

const SoilAuthorizationForm = props => {
  const {
    bookID,
    postSaveSendRequest,
    postSaveSendLoad,
    getAvailability,
    availabilityData,
    postSaveSend,
    requesting,
    postSaveSendRequestSuccess,
    postSaveSendError,
    postSaveSendRequestFailure
  } = props
  const { loginInfo } = useSelector(
    state => state.login
  )
  const initial = {
    licence_plate: "",
    truck_tracking: "",
    truck_company: "",
    request_availability: bookID
  }
  const [truckTracking, setTruckTracking] = useState([initial])

  useEffect(() => {
    if (bookID) {
      getAvailability(bookID)
    }
  }, [bookID])

  useEffect(() => {
    if (postSaveSend[0]?.id) {
      getAvailability(bookID)
      setTruckTracking([initial])
      postSaveSendRequestSuccess(false)
    }
  }, [postSaveSend[0]?.id])

  const onSaveSend = () => {
    if (availabilityData?.trucking_detail.length === availabilityData.booked_value) {
      // toast.error(" Truck booked limit has been reached", {
      //   id: 'card',
      // });
      toast.error("The maximum number of truck bookings has been reached", {
        id: 'card',
      });
    } else {
      // if (truckTracking.map((a) => a.licence_plate) || truckTracking.map((a) => a.truck_tracking) || truckTracking.map((a) => a.truck_company)) {
      //   loginInfo?.user?.user_status &&
      //     toast.error("Mandatory fields are required")
      // }
      postSaveSendRequest(truckTracking)
      // if (truckTracking) {

      postSaveSendRequestFailure(false)
      // }
      // if (!postSaveSendError) {

      // postSaveSendRequestFailure(false)
      // }
    }

  }

  const onAddBtnClick = event => {
    event.preventDefault()
    setTruckTracking(prev => [...prev, initial])
  }

  const handleChange = (e, index, name) => {
    const truckTrackings = [...truckTracking]
    truckTrackings[index][name] = e.target.value
    setTruckTracking(truckTrackings)
  }
  const columns = [
    {
      title: "License plate",
      dataIndex: "license_plate",
      key: "license_plate",
      render: item => {
        return (
          <Space
            size="middle"
            className="full-name"
            style={{ cursor: "pointer" }}
            title={item?.length >= 17 ? item : null}
          >
            {item && item?.length >= 17 ? item.slice(0, 17) + "..." : item}
          </Space>
        )
      }
    },
    {
      title: "Truck configuration",
      dataIndex: "truck_config",
      key: "truck_config",
      render: truck_config => {
        return (
          <Space
            size="middle"
            className="full-name"
            style={{ cursor: "pointer" }}
            title={truck_config.length >= 17 ? truck_config : null}
          >
            {truck_config && truck_config?.length >= 17
              ? truck_config.slice(0, 17) + "..."
              : truck_config}
          </Space>
        )
      }
    },
    {
      title: "Company name",
      dataIndex: "company_name",
      key: "company_name",
      render: company_name => {
        return (
          <Space
            size="middle"
            className="full-name"
            style={{ cursor: "pointer" }}
            title={company_name.length >= 20 ? company_name : null}
          >
            {company_name && company_name?.length >= 20
              ? company_name.slice(0, 20) + "..."
              : company_name}
          </Space>
        )
      }
    },
    {
      title: "Leave Time",
      dataIndex: "leave_time",
      key: "leave_time",
      render: leave_time => {
        return (
          <>
            <span style={{ fontWeight: "600" }}>
              {moment(leave_time).format("DD MMM")}
            </span>{" "}
            /{" "}
            <span style={{ fontWeight: "500" }}>
              {moment(leave_time).format("LT")}
            </span>
          </>
        )
      }
    },

    {
      title: "Confirm Arrival",
      dataIndex: "status",
      key: "status",
      render: (status, item, ind) => {
        return (
          <>
            {status == "pending" ? (
              <>On the way</>
            ) : (
              <>
                <span style={{ fontWeight: "600" }}>
                  {moment(item.confirm_arrival).format("DD MMM")}
                </span>{" "}
                /{" "}
                <span style={{ fontWeight: "500" }}>
                  {moment(item.confirm_arrival).format("LT")}
                </span>{" "}
              </>
            )}
          </>
        )
      }
    }
  ]
  useEffect(() => {
    postSaveSendRequestFailure(false)
  }, [])

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        <section className="content-section soil-form-section">
          <div className="soil-form-content-section">
            <div className="heading-soil-form">
              <div className="back" onClick={() => {
                history.goBack()
                postSaveSendRequestFailure(false)
              }}>
                <img src={backIcon} alt="" />
              </div>
              <h1>Soil Authorization Form Details</h1>
            </div>
            <div className="soil-authorization-form">
              <div className="container">
                <div className="row top-row">
                  <div className="col-md-12 form-heading">
                    {/* { availabilityData?.contractor_detail?.email?.split('@')[0]} */}

                  </div>
                  <div className="col-md-4 details-wrapper">
                    <div className="details">
                      Address:
                      <span>
                        {availabilityData?.contractor_detail?.address || ""}
                      </span>
                    </div>
                    <div className="details">
                      Email:
                      <span>
                        {availabilityData?.contractor_detail?.email || ""}
                      </span>
                    </div>
                    <div className="details">
                      Phone:
                      <span>
                        {availabilityData?.contractor_detail?.phone || ""}
                      </span>
                    </div>
                    <div className="details">
                      Position:
                      <span>
                        {availabilityData?.contractor_detail?.position || ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 details-wrapper">
                    <div className="details">
                      Originating location:
                      <span>
                        {availabilityData?.admin_detail?.ori_location || ""}
                      </span>
                    </div>
                    <div className="details">
                      My Earth Network PO#:
                      <span>
                        {availabilityData?.admin_detail?.aes_po || ""}
                      </span>
                    </div>
                    <div className="details">
                      CSR:
                      <span>{availabilityData?.admin_detail?.csr || ""}</span>
                    </div>
                    <div className="details">
                      Laboratory file #:
                      <span>
                        {availabilityData?.admin_detail?.lab_file || ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 details-wrapper">
                    <div className="details">
                      Receiving site:
                      <span>{availabilityData?.site_detail?.name || ""}</span>
                    </div>
                  </div>
                </div>
                <div className="row bottom-row">
                  <div className="col-md-12 form-heading">
                    Trucking information:
                  </div>
                  {!availabilityData && requesting ? (
                    <Table
                      rowKey="key"
                      pagination={false}
                      dataSource={[...Array(5)].map((_, index) => ({
                        key: `key${index}`
                      }))}
                      columns={columns.map(column => {
                        return {
                          ...column,
                          render: function renderPlaceholder() {
                            return <Skeleton />
                          }
                        }
                      })}
                    />
                  ) : (
                    <Table
                      className="trackingStyle"
                      columns={columns}
                      dataSource={availabilityData?.trucking_detail}
                      pagination={false}
                      rowKey="id"
                      scroll={{ y: 560 }}
                    />
                  )}

                  <div className="col-md-12 form-heading">Add Trucks</div>

                  {truckTracking.map((item, index) => (
                    <>
                      <div className="col-md-4 input-details-wrapper">
                        <Input
                          placeholder="Enter licence plate"
                          type="text"
                          showLabel={true}
                          label="License plate #"
                          value={item.licence_plate}
                          onChange={val => {
                            handleChange(val, index, "licence_plate")
                          }}
                          showError={true}
                          errorMessage={

                            (!item.licence_plate &&
                              postSaveSendError[0]?.licence_plate
                            )
                          }
                        // errorMessage={
                        //   postSaveSendError &&
                        //   postSaveSendError[0]?.licence_plate
                        // }
                        />
                      </div>
                      <div className="col-md-4 input-details-wrapper">
                        <Input
                          placeholder="Enter Truck config"
                          type="text"
                          showLabel={true}
                          label="Truck config"
                          value={item.truck_tracking}
                          onChange={val =>
                            handleChange(val, index, "truck_tracking")
                          }
                          showError={true}
                          errorMessage={

                            (!item.truck_tracking &&
                              postSaveSendError[0]?.truck_tracking
                            )
                          }
                        />
                      </div>
                      <div className="col-md-4 input-details-wrapper">
                        <Input
                          placeholder="Company name"
                          type="text"
                          showLabel={true}
                          label="Enter company name"
                          value={item.truck_company}
                          onChange={val =>
                            handleChange(val, index, "truck_company")
                          }
                          showError={true}
                          errorMessage={

                            (!item.truck_company &&
                              postSaveSendError[0]?.truck_company
                            )
                          }
                        />
                      </div>
                    </>
                  ))}
                  <div className="button-wrapper">
                    <Button
                      title="Send to the site filed"
                      onClick={onSaveSend}
                      showSpinner={postSaveSendLoad}
                    />
                  </div>
                </div>
                <div className="row button-row">
                  <div className="col-md-4 left-wrapper">
                    <div className="details">
                      <h3>Q.E.P.</h3>
                      <div className="field">
                        {availabilityData?.qep_signature || ""}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-4 right-wrapper">
                    <div className="button-wrapper">
                      <Button
                        title="Save and Send to the Side Filed"
                        onClick={onSaveSend}
                        showSpinner={postSaveSendLoad}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </HomeLayout>
    </>
  )
}

const mapStateToProps = state => ({
  bookID: state.login.bookID,
  availabilityData: state.SoilAuthorizationForm.availabilityData,
  postSaveSendLoad: state.SoilAuthorizationForm.postSaveSendLoad,
  postSaveSend: state.SoilAuthorizationForm.postSaveSend,
  requesting: state.schedule.requesting,
  postSaveSendError: state.SoilAuthorizationForm.postSaveSendError
})

const mapDispatchToProps = dispatch => ({
  postSaveSendRequest: data => dispatch(postSaveSendRequest(data)),
  getAvailability: data => dispatch(getAvailability(data)),
  postSaveSendRequestSuccess: data => dispatch(postSaveSendRequestSuccess(data)),
  postSaveSendRequestFailure: data => dispatch(postSaveSendRequestFailure(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SoilAuthorizationForm)
