import {
 
  GET_SITE_API_DATA,
  GET_SITE_API_DATA_SUCCESS,
  GET_SITE_API_DATA_FAILURE,
  POST_SITE_API_DATA_SUCCESS,
  POST_SITE_API_DATA,
  POST_SITE_API_DATA_FAILURE
} from "./type"

export const getData = () => ({
  type: GET_SITE_API_DATA
})

export const getDataSuccess = data => ({
  type: GET_SITE_API_DATA_SUCCESS,
  data
})

export const getDataFailure = data => ({
  type: GET_SITE_API_DATA_FAILURE,
  data
})

export const postData = data => ({
  type: POST_SITE_API_DATA,
  data
})

export const postDataSuccess = data => ({
  type: POST_SITE_API_DATA_SUCCESS,
  data
})

export const postDataFailure = data => ({
  type: POST_SITE_API_DATA_FAILURE,
  data
})

