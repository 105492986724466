import React, { useState, useEffect, useRef } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"
import plus from "../../../assets/images/plus.svg"
import pdfupload from "../../../assets/images/pdfupload.svg"
import uploadfile from "../../../assets/images/uploadfile.svg"
import Button from "../../../components/Button"
import { connect } from "react-redux"
import Input from "../../../components/Input"
import TextArea from "../../../components/TextArea"
import Materialneeded from "./components/Materialneeded"
import RequestSoilModal from "../../../constratarsModal/RequestSoilModal"
import { toast } from 'react-hot-toast'
import {
  soilDepositRequest,
  soilDepositRequestFailure,
  getMaterialRequest
} from "./redux/action"

const RequestSoilDeposit = props => {
  const {
    soilDepositRequest,
    soilDepositRequestFailure,
    getMaterialRequest,
    soilDepositData,
    materialNames,
    error,
    requesting,
    loginInfo
  } = props

  const material = {
    material_name: "",
    material_type: "",
    material_mass_type: "",
    material_amount: ""
  }

  const [selectedFile, setSelectedFile] = useState(false)
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [materialOption, setMaterialOption] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [materialNeed, setMaterialNeed] = useState([material])
  const [specialMaterial, setSpecialMaterial] = useState("")
  const [materialType, setMaterialType] = useState("")
  const [laboratoryFileNumber, setLaboratoryFileNumber] = useState("")
  const [originatingLocation, setOriginatingLocation] = useState("")

  const [materialMassType, setMaterialMassType] = useState(false)
  const [materialAmount, setMaterialAmount] = useState("")
  const [note, setNote] = useState("")

  const fileRef = useRef()
  const specialFormRef = useRef()
  const componetFormRef = useRef()
  useEffect(() => {
    getMaterialRequest()
    soilDepositRequestFailure(false)
  }, [])



  useEffect(() => {
    materialNames.length > 0 && fillMeterialNames()
  }, [materialNames])

  const fillMeterialNames = () => {
    const materialOptions = []
    if (materialNames.length > 0) {
      materialNames.map(item =>
        materialOptions?.push({
          value: item.id,
          label: item.name
        })
      )
      setMaterialOption(materialOptions)
    }
  }
  // const options = materialNames?.map((items, index) => {
  //   return (
  //     <Option key={index} value={items.code}>
  //       {items.name}
  //     </Option>
  //   )
  // })

  // const handleChangeup = e => {
  //   setSelectedFile(e.target.files[0])
  //   setIsFilePicked(true)
  // }
  const handleChangeup = e => {
    const file = e.target.files[0]
    if (file.size > 50 * 1024 * 1024) {
      alert("File size exceeds 50 MB. Please select a smaller file.")
      setIsFilePicked(false)
    } else {
      setSelectedFile(file)
      setIsFilePicked(true)
    }
  }
  useEffect(() => {
    setModalShow(false)
  }, [])

  const onAddBtnClick = event => {
    event.preventDefault()
    setMaterialNeed([...materialNeed, material])
  }

  const handleChange = (e, index, name) => {
    const materials = [...materialNeed]
    materials[index][name] = e.target ? e.target.value : e
    setMaterialNeed(materials)
  }



  const clearData = () => {
    setModalShow(true)
    componetFormRef.current.reset()
    specialFormRef.current.reset()
  }

  useEffect(() => {
    if (soilDepositData.msg) {
      setMaterialMassType("")
      // setMaterialNeed([])
      setNote("")
      setSpecialMaterial("")
      setMaterialAmount("")
      // specialFormRef.current.reset()
      specialFormRef.current.reset()

      setSelectedFile(false)
      setIsFilePicked(false)

      setMaterialNeed([material])
      soilDepositRequestFailure(false)
      // setTimeout(() => {

      // }, 10)
    }
  }, [soilDepositData.msg])
  const [files, setFiles] = useState([])

  const handleFileChange = event => {
    const fileList = event.target.files
    // setSelectedFile([...fileList])
    const updatedFiles = [...files]

    for (let i = 0; i < fileList.length; i++) {
      updatedFiles.push(fileList[i])
    }

    setFiles(updatedFiles)

  }

  const handleFileDelete = index => {
    const updatedFiles = [...files]
    updatedFiles.splice(index, 1)
    setFiles(updatedFiles)

  }
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("material_need", JSON.stringify(materialNeed));
    formData.append("special_request", specialMaterial);
    formData.append("material_type", materialType);
    formData.append("material_mass_type", materialMassType);
    formData.append("material_amount", materialAmount);
    formData.append("note", note);
    formData.append("originating_location", originatingLocation);
    formData.append("laboratory_file", laboratoryFileNumber);
    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
    });

    await soilDepositRequest(formData, clearData);

    const Material = materialNeed[0]

    if (
      !Material?.material_name ||
      !Material?.material_amount ||
      !Material?.material_type ||
      !Material?.material_mass_type ||
      !laboratoryFileNumber ||
      !originatingLocation
    ) {
      loginInfo?.user?.user_status && toast.error("Mandatory fields are required")
    }
  };

  return (
    <>
      <HomeLayout dashboardHeader={true}>
        {loginInfo && loginInfo?.user?.role == "Operator" ? (
          <div className="errordiv">
            <p>You are Operator and not permitted</p>
          </div>
        ) : (
          <section className="content-section Material-section">
            <div className=" w-100 p-3" style={{ overflowX: "hidden" }}>
              <div className="headerText">
                <h1>Material Needed</h1>
              </div>

              {/* body */}

              <div className="doted">
                <Materialneeded
                  materialNeed={materialNeed}
                  setMaterialNeed={setMaterialNeed}
                  onChangeText={handleChange}
                  materialNames={materialOption}
                  errors={error}
                  componetFormRef={componetFormRef}
                />
                <div className="button-wrapper">
                  <Button
                    type="button"
                    onClick={onAddBtnClick}
                    showIcon={true}
                    src={plus}
                    title="Add More"
                    className="aes-btn transparent-btn"
                  />
                </div>
              </div>
              <form className="row body-screen mt-4" ref={specialFormRef}>
                <div className="col-md-3">
                  <label className="label">Special request</label>
                  <Input
                    placeholder="Enter text"
                    type="text"
                    value={specialMaterial}
                    errorMessage={error?.special_request}
                    showError={true}
                    onChange={e => setSpecialMaterial(e.target.value)}
                  />
                </div>
                <div className="col-md-2">
                  <label className="label">Material type</label>
                  <label className="checkbox-wrapper2">
                    <Input
                      type="radio"
                      className="checkbox-round"
                      name="smaterialtype"
                      radioGroup="Mt"
                      value="1"
                      onChange={e => setMaterialType(e.target.value)}
                    />

                    <span>Structural</span>
                  </label>

                  <label
                    className={`error-message text-center ${error && error?.material_type ? "opacity-1" : ""
                      }`}
                  >
                    {error && error?.material_type}
                  </label>
                </div>
                <div className="col-md-2 checkbox-wrapper2">
                  <label className="check">
                    <Input
                      type="radio"
                      className="checkbox-round"
                      name="smaterialtype"
                      radioGroup="Mt"
                      value="2"
                      onChange={e => setMaterialType(e.target.value)}
                    />

                    <span>Non-structural</span>
                  </label>
                </div>

                <div className="col-lg-3 col-md-4">
                  <label className="label">Material amount</label>
                  <div className="d-flex justify-content-between ">
                    <div className="checkbox-wrapper2">
                      <label className="check">
                        <input
                          type="radio"
                          className="checkbox-round"
                          name="smaterialamount"
                          value="1"
                          onChange={e => setMaterialMassType(e.target.value)}
                        />
                        <span>Metric tonnes</span>
                      </label>
                    </div>
                    <div className="checkbox-wrapper2">
                      <label className="check">
                        <input
                          type="radio"
                          className="checkbox-round"
                          name="smaterialamount"
                          value="2"
                          onChange={e => setMaterialMassType(e.target.value)}
                        />
                        <span>
                          m<sup>3</sup>{" "}
                        </span>
                      </label>
                    </div>
                  </div>
                  <label
                    className={`error-message text-center ${error && error?.material_mass_type ? "opacity-1" : ""
                      }`}
                  >
                    {error && error?.material_mass_type}
                  </label>
                </div>

                <div className="col-md-2">
                  <div className="input-wrapper mt-4">
                    <Input
                      type="number"
                      placeholder="00.00"
                      value={materialAmount}
                      showError={true}
                      errorMessage={error?.material_amount}
                      onChange={e => setMaterialAmount(e.target.value)}
                    />
                  </div>
                </div>
              </form>
              <div className="row " style={{ gap: 30 }}>
                <div className="col-md-4">
                  <Input
                    placeholder="Enter laboratory file number "
                    label="Laboratory file #: "
                    type="text"
                    showLabel={true}
                    showError={true}
                    onChange={e => setLaboratoryFileNumber(e.target.value)}
                    errorMessage={error?.laboratory_file}
                    value={laboratoryFileNumber}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    placeholder="Enter originating location"
                    label="Originating location:"
                    type="text"
                    showLabel={true}
                    showError={true}
                    onChange={e => setOriginatingLocation(e.target.value)}
                    errorMessage={error?.originating_location}
                    value={originatingLocation}
                  />
                </div>
              </div>
              <div className="mt-2 ">
                <label>Notes</label>
                <TextArea
                  placeholder="Type notes"
                  value={note}
                  onChange={e => setNote(e.target.value)}
                />
                <label
                  className={`error-message ${error && error?.note ? "opacity-1" : ""
                    }`}
                >
                  {error && error?.note ? error?.note : ""}
                </label>
              </div>
              <hr />
              <div className="fotter">
                <h2>Environmental/ geotechnical analytical data</h2>
              </div>

              <div className="upload-file-soil">
                <img
                  src={isFilePicked == false ? pdfupload : uploadfile}
                  alt="upload"
                />

                <div className="icon-wrapper d-flex " style={{ gap: "30px", }}>
                  <input
                    type="file"
                    name="file"
                    ref={fileRef}
                    accept=".pdf"
                    multiple
                    onChange={handleFileChange}
                    hidden
                  />
                  {/* {isFilePicked == false ? ( */}
                  <div onClick={() => fileRef.current.click()}>
                    <p>
                      <a href="#">Upload File</a>
                    </p>
                  </div>
                  {/* ) : ( */}
                  {/* <p>
                      <a
                        onClick={() => {
                          setSelectedFile(false)
                          setIsFilePicked(false)
                          fileRef.current.value = null
                        }}
                        href="#"
                      >
                        Remove File
                      </a>
                    </p>
                  )} */}
                </div>
              </div>
            </div>
            <div>
              <label
                className={`error-message  ${error && !selectedFile && error?.environmental
                  ? "opacity-1"
                  : ""
                  }`}
              >
                {!selectedFile && error?.environmental}
              </label>
              <div className="mx-1" >
                {files.map((file, index) => (
                  <div className="file-item mb-2" key={index}>
                    <p className="file-name">{file.name}</p>
                    <div
                      className="action"
                      onClick={() => handleFileDelete(index)}
                    >
                      {/* <img src={deleteICon} alt="del" /> */}
                      <p>

                        Remove
                      </p>
                    </div>
                    {/* <span
                      className="delete-icon"
                      onClick={() => handleFileDelete(index)}
                    >
                      X
                    </span> */}
                  </div>
                ))}
              </div>
              <div className="button-wrapper pb-4 mt-4">
                <Button
                  showSpinner={requesting}
                  title="Submit A Request"
                  className="aes-btn"
                  onClick={() => {
                    handleSubmit()
                  }}
                />
              </div>
            </div>
          </section>
        )}

        {modalShow && (
          <RequestSoilModal
            modalShow={modalShow}
            setModalShow={setModalShow}
            body={soilDepositData.msg}
            componetFormRef={componetFormRef}
          />
        )}
      </HomeLayout>
    </>
  )
}
const mapStateToProps = state => ({
  soilDepositData: state.soilDeposit.soilDepositdata,
  requesting: state.soilDeposit.requesting,
  error: state.soilDeposit.error,
  materialNames: state.soilDeposit.materialNameData,
  loginInfo: state.login.loginInfo
})
const mapDispatchToProps = dispatch => ({
  soilDepositRequest: (data, clearData) =>
    dispatch(soilDepositRequest(data, clearData)),
  getMaterialRequest: data => dispatch(getMaterialRequest(data)),
  soilDepositRequestFailure: data => dispatch(soilDepositRequestFailure(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(RequestSoilDeposit)
