// INVOICE
export const GET_INVOICE_DATA = "GET_INVOICE_DATA"
export const GET_INVOICE_DATA_SUCCESS = "GET_INVOICE_DATA_SUCCESS"
export const GET_INVOICE_DATA_FAILURE = "GET_INVOICE_DATA_FAILURE"

export const GET_SINGLE_INVOICE_DATA = "GET_SINGLE_INVOICE_DATA"
export const GET_SINGLE_INVOICE_DATA_SUCCESS = "GET_SINGLE_INVOICE_DATA_SUCCESS"
export const GET_SINGLE_INVOICE_DATA_FAILURE = "GET_SINGLE_INVOICE_DATA_FAILURE"

export const PATCH_INVOICE_STATUS = "PATCH_INVOICE_STATUS"
export const PATCH_INVOICE_STATUS_SUCCESS = "PATCH_INVOICE_STATUS_SUCCESS"
export const PATCH_INVOICE_STATUS_FAILURE = "PATCH_INVOICE_STATUS_FAILURE"