import React, { useEffect, useState } from "react"
import "./style.scss"
import HomeLayout from "../../../layout/HomeLayout"
import Search from "../../../components/Search"
import Button from "../../../components/Button"
import { Col, Row, Select, TimePicker } from "antd"
import Input from "../../../components/Input"
import AesPhoneInput from "../../../components/PhoneInput"
import {
  getAdmindashboardList,
  getAdmindashboardUpdateUser,
  getMaterialRequest,
  deleteFillRequest
} from "../AdminDashboard/redux/action"
import dayjs from "dayjs"

import { connect } from "react-redux"
import useForm from "../../../utils/useForm"
import AeSelect from "../../../components/AeSelect"
import backIcon from "../../../assets/images/back-icon-circle.svg"
import { history } from "../../../reduxStore/store"
import { Toaster } from "react-hot-toast"

const EditAndViewUser = props => {
  const {
    getAdmindashboardList,
    userListRequestList,
    getAdmindashboardUpdateUser,
    getMaterialRequest,
    materialNames,
    requesting,
    userListRequesting,
    updateUserRequesting,
    deleteFillRequest
  } = props

  const [isDisable, setIsDisable] = useState(false)
  const [isEdit, setIsEdit] = useState(true)

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    phone_number: {
      value: "",
      error: ""
    },
    street: {
      value: "",
      error: ""
    },
    csr: {
      value: "",
      error: ""
    },
    booking_limit: {
      value: "",
      error: ""
    },
    fill_site_area: {
      value: "",
      error: ""
    },
    load_finished: {
      value: "",
      error: ""
    },
    load_scheduled: {
      value: "",
      error: ""
    },
    freeload: {
      value: "",
      error: ""
    }
  }
  const validationSchema = {
    email: {
      required: false
    },
    phone_number: {
      required: false
    },
    street: {
      required: false
    },
    csr: {
      required: false
    },
    booking_limit: {
      required: false
    },
    fill_site_area: {
      required: false
    },
    load_finished: {
      required: false
    },
    load_scheduled: {
      required: false
    },
    freeload: {
      required: false
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationSchema
  )

  const HandleUpdateUser = async () => {
    let apiData = {
      email: state.email.value,
      phone_number:
        state.phone_number.value[0] === "+"
          ? state.phone_number.value
          : `+${state.phone_number.value}`,
      user_detail: {
        street: state.street.value
      },
      fill_site: {
        csr: state.csr.value || "",
        booking_limit: Number(state.booking_limit.value),
        fill_site_area: state.fill_site_area.value,
        load_finished: Number(state.load_finished.value),
        load_scheduled: Number(state.load_scheduled.value),
        freeload: Number(state.freeload.value)
      }
    }
    getAdmindashboardUpdateUser(userListRequestList?.id, apiData)
    history.push("dashboard")

  }
  const options = materialNames?.map(item => ({
    value: item.name,
    label: item.name
  }))
  useEffect(() => {
    if (!requesting && !userListRequesting) {
      setState(prevState => ({
        ...prevState,
        email: {
          ...prevState.email,
          value: userListRequestList?.email
        },
        phone_number: {
          ...prevState.phone_number,
          value: userListRequestList?.phone_number
        },
        street: {
          ...prevState?.street,
          value: userListRequestList?.user_detail?.street
        },
        csr: {
          ...prevState?.csr,
          value: userListRequestList?.fill_site?.csr
        },
        booking_limit: {
          ...prevState?.booking_limit,
          value: userListRequestList?.fill_site?.booking_limit
        },
        fill_site_area: {
          ...prevState?.fill_site_area,
          value: userListRequestList?.fill_site?.fill_site_area
        },
        load_finished: {
          ...prevState?.load_finished,
          value: userListRequestList?.fill_site?.load_finished
        },
        load_scheduled: {
          ...prevState?.load_scheduled,
          value: userListRequestList.fill_site?.load_scheduled
        },
        freeload: {
          ...prevState?.freeload,
          value: userListRequestList.fill_site?.freeload
        }
      }))
    }
  }, [userListRequestList])
  const AdmindashboardId = localStorage.getItem("AdmindashboardId")
  useEffect(() => {
    getMaterialRequest()
    getAdmindashboardList(`${AdmindashboardId}/`)
  }, [])

  return (
    <HomeLayout dashboardHeader={true}>
      <Toaster />
      <section className="content-section adminDashboard-section">
        <div className="headingStyle">
          <span>
            <div className="back" onClick={() => history.goBack()}>
              <img src={backIcon} alt="" style={{ marginBottom: 8 }} />
            </div>
            <h1 className="w-100">Fill Site Detail</h1>
          </span>
          <form>
            {/* <Search placeholder="Search by contractor" onChange={e => {}} /> */}
          </form>
          <div className="buttondiv">
            <Button
              title="Add New Fill Site"
              className="newFileStyle"
              onClick={e => history.push("new-file-site")}
            />
          </div>
        </div>
        <div className="editViewContainer">
          <div className="title-row border-bottom pb-3">
            <h1>{userListRequestList?.name}</h1>
            <div className="btn-div text-end">
              {isEdit && (
                <>
                  <Button
                    title="Save"
                    className="BtnsStyle"
                    onClick={() => HandleUpdateUser()}
                    showSpinner={updateUserRequesting}
                  />

                  <Button
                    title="Remove"
                    className="BtnsStyle"
                    onClick={e => {

                      deleteFillRequest(`${userListRequestList?.id}`)
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <Row gutter={10}>
            <Col xs={24} sm={12} className="my-2">
              <h2>Location:</h2>
              <Input
                type="text"
                className="InputStyle"
                value={state?.street?.value}
                disabled={isDisable}
                showError={false}
                onChange={val => handleOnChange("street", val?.target?.value)}
              />
            </Col>
            <Col xs={24} sm={12} className="my-2">
              <h2>Contact phone:</h2>
              <AesPhoneInput
                placeholder="Enter phone"
                type="number"
                className="InputStyle"
                showError={false}
                disabled={isDisable}
                value={state?.phone_number?.value}
                onChange={val => handleOnChange("phone_number", val)}
              />
            </Col>
            <Col xs={24} sm={12} className="my-2">
              <h2>CSR</h2>
              <AeSelect
                options={options}
                BoardSelect
                onChange={val => handleOnChange("csr", val)}
                value={state.csr.value}
                disabled={isDisable}
              />
            </Col>
            <Col xs={24} sm={12} className="my-2">
              <h2>Email:</h2>
              <Input
                type="email"
                className="InputStyle"
                disabled={isDisable}
                value={state.email.value}
                onChange={val => handleOnChange("email", val.target.value)}
              />
            </Col>
            <Col xs={24} sm={12} className="my-2">
              <h2>Set pre-booking limitations:</h2>
              <Input
                type="number"
                disabled={isDisable}
                className="InputStyle"
                value={state.booking_limit.value}
                showError={false}
                onChange={val =>
                  handleOnChange("booking_limit", val.target.value)
                }
              />
            </Col>
            <Col xs={24} sm={12} className="my-2">
              <h2>Select areas of the site are being filled:</h2>
              <Input
                type="text"
                disabled={isDisable}
                className="InputStyle"
                value={state.fill_site_area.value}
                showError={false}
                onChange={val =>
                  handleOnChange("fill_site_area", val.target.value)
                }
              />
            </Col>
            <Col xs={24} sm={12} className="my-2">
              <h2>Number of loads finished: </h2>
              <Input
                type="number"
                disabled={isDisable}
                className="InputStyle"
                value={state.load_finished.value}
                showError={false}
                onChange={val =>
                  handleOnChange("load_finished", val.target.value)
                }
              />
            </Col>
            <Col xs={24} sm={12} className="my-2">
              <h2>Number of loads scheduled:</h2>
              <Input
                disabled={isDisable}
                type="number"
                className="InputStyle"
                value={state.load_scheduled.value}
                showError={false}
                onChange={val =>
                  handleOnChange("load_scheduled", val.target.value)
                }
              />
            </Col>
            <Col xs={24} sm={12} className="my-2">
              <h2>Freeload slots:</h2>
              <Input
                type="number"
                disabled={isDisable}
                className="InputStyle"
                value={state.freeload.value}
                showError={false}
                onChange={val => handleOnChange("freeload", val.target.value)}
              />
            </Col>
          </Row>
        </div>
      </section>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  userListRequestList: state.adminDashboardUserData.userListRequestList,
  updateUserRequest: state.adminDashboardUserData.updateUserRequest,
  materialNames: state.adminDashboardUserData.materialNameData,
  requesting: state.adminDashboardUserData.requesting,
  userListRequesting: state.adminDashboardUserData.userListRequesting,
  updateUserRequesting: state.adminDashboardUserData.updateUserRequesting
})

const mapDispatchToProps = dispatch => ({
  getAdmindashboardList: data => dispatch(getAdmindashboardList(data)),
  getAdmindashboardUpdateUser: (id, data) =>
    dispatch(getAdmindashboardUpdateUser(id, data)),
  getMaterialRequest: () => dispatch(getMaterialRequest()),
  deleteFillRequest: id => dispatch(deleteFillRequest(id)),

})

export default connect(mapStateToProps, mapDispatchToProps)(EditAndViewUser)
