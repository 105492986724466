import React, { Component, useEffect, useState } from "react"
import { ProSidebarProvider } from "react-pro-sidebar"
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar"
import "./style.scss"
import { routes } from "../../Router"
import { history } from "../../reduxStore/store"
import { connect } from "react-redux"
// import { logOut } from "../../pages/AuthScreens/Login/redux/action"
import { useLocation } from "react-router-dom"
import { Images } from "../../theme/Images"


const SidebarLayout = props => {
  const { loginInfo } = props
  const { collapseSidebar, toggleSidebar } = useProSidebar()
  const [activeElement, setActiveElement] = useState("")
  const newRoute = routes.filter(item => item.layout === "/admin")
  const location = useLocation()

  useEffect(() => {
    setActiveElement(location.pathname)
  }, [location.pathname])

  // const logOut = () => {
  //   localStorage.clear()
  //   props.logOut()
  // }

  const renderMenuItem = (item, i) => {
    return (
      <MenuItem
        key={i}
        className="menu-item"
        onClick={() => {
          history.push(item.layout + item.path)
        }}
      >
        <div
          className={`icon-text ${item.layout + item.path === activeElement ? "active" : ""
            }`}
        >
          <img src={item.activeIconSrc} className="menu-icon-active" alt="" />
          <img src={item.iconSrc} className="menu-icon" alt="" />
          <div className="text">{item.name}</div>
        </div>
      </MenuItem>
    )
  }
  return (
    <>
      <Sidebar
        customBreakPoint="992px"
        // defaultCollapsed={true}
        className="sidebar"
      >
        <div className="top-menu">
          <Menu>
            <MenuItem className="logo-header">
              <div className="logo-wrapper">
                <img src={Images.mainlogo} alt="GROUNDCONTROL Soil & Aggregate Platform" className="img-fluid" />
              </div>
            </MenuItem>
            {/* <div className="admin-sidebar-title">
              Admin
            </div> */}

            {routes.map((item, i) => {
              if (
                item.isShow == "contractor" &&
                "contractor" == loginInfo?.user.user_type
              ) {
                if (item?.role?.includes(loginInfo?.user?.role)) {
                  return renderMenuItem(item, i)
                }
                if (!loginInfo?.user.role) {
                  return renderMenuItem(item, i)
                }
              }

              //   if(item.isShow == 'admin' && 'admin' == loginInfo?.user.user_type) {

              //      return renderMenuItem(item, i)

              //  }
              if (
                item.isShow == "admin" &&
                "admin" == loginInfo?.user.user_type
              ) {
                if (item?.role?.includes(loginInfo?.user?.role)) {
                  return renderMenuItem(item, i)
                }
                if (!loginInfo?.user.role) {
                  return renderMenuItem(item, i)
                }
              }

              if (
                item.isShow == "owner" &&
                "owner" == loginInfo?.user.user_type
              ) {
                return renderMenuItem(item, i)
              }
            })}
          </Menu>
        </div>
      </Sidebar>
      <main className="toggle-sidebar-icon">
        {/* <button onClick={() => toggleSidebar()}>Toggle</button> */}
        {/* <img src={toggleIcon} alt="" onClick={() => toggleSidebar()} /> */}
      </main>
    </>
  )
}

const SidebarMenu = props => {
  return (
    <ProSidebarProvider>
      {
        <SidebarLayout
          // logOut={props.logOut}
          loginInfo={props.loginInfo}
        />
      }
    </ProSidebarProvider>
  )
}
const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  // logOut: data => dispatch(logOut(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
